import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule, routes} from './app-routing.module';
import {AppComponent} from './app.component';
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from "@angular/common";
import localeFr from '@angular/common/locales/fr';
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatCardModule} from "@angular/material/card";
import {MatButtonModule} from "@angular/material/button";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatListModule} from "@angular/material/list";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatDialogModule} from "@angular/material/dialog";
import {MatBadgeModule} from "@angular/material/badge";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatDividerModule} from "@angular/material/divider";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatSidenavModule} from "@angular/material/sidenav";
import {SignInDialogComponent} from './dialog/sign-in-dialog/sign-in-dialog.component';
import {SignUpDialogComponent} from './dialog/sign-up-dialog/sign-up-dialog.component';
import {ReactiveFormsModule} from "@angular/forms";
import {APIService} from "./service/api.service";
import {AngularFireModule} from "@angular/fire";
import {environment} from "../environments/environment";
import {AngularFireAnalyticsModule, CONFIG, ScreenTrackingService, UserTrackingService} from "@angular/fire/analytics";
import {AngularFireAuthModule} from "@angular/fire/auth";
import {MyMatInputComponent} from './ui/my-mat-input/my-mat-input.component';
import {DartToTsComponent} from './tools/dart-to-ts/dart-to-ts.component';
import {DeviceDetectorModule} from "ngx-device-detector";
import {ClipboardModule} from "@angular/cdk/clipboard";
import {ProfileComponent} from './pages/profile/profile.component';
import {AuthenticationService} from "./service/AuthenticationService";
import {TosComponent} from './pages/tos/tos.component';
import {PpComponent} from './pages/pp/pp.component';
import {HomeComponent} from './pages/home/home.component';
import {MarketComponent} from './pages/market/market.component';
import {RoomComponent} from './pages/room/room.component';
import {MatTabsModule} from "@angular/material/tabs";
import {ItemCardContainerComponent} from './ui/item-card-container/item-card-container.component';
import {CritterpediaComponent} from './pages/critterpedia/critterpedia.component';
import {CollectedIconComponent} from './ui/collected-icon/collected-icon.component';
import {LoadingComponent} from './ui/loading/loading.component';
import {MatRippleModule} from "@angular/material/core";
import {CommonItemListComponent} from './ui/common-item-list/common-item-list.component';
import {FossilComponent} from './pages/fossil/fossil.component';
import {FurnitureComponent} from './pages/furniture/furniture.component';
import {SongComponent} from './pages/song/song.component';
import {VillagerComponent} from './pages/villager/villager.component';
import {MatSelectModule} from "@angular/material/select";
import {FooterComponent} from './footer/footer.component';
import { ChecklistComponent } from './pages/checklist/checklist.component';
import { CritterpediaDetailComponent } from './pages/critterpedia-detail/critterpedia-detail.component';
import { CritterpediaDialogComponent } from './dialog/critterpedia-dialog/critterpedia-dialog.component';
import {ShareModule} from "ngx-sharebuttons";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {MatPaginatorModule} from "@angular/material/paginator";
import { DartToTypescriptToolComponent } from './pages/dart-to-typescript-tool/dart-to-typescript-tool.component';
import { RoomItemViewComponent } from './pages/room/room-item-view/room-item-view.component';
import { UserProfileRowComponent } from './ui/user-profile-row/user-profile-row.component';
import { CommonTagComponent } from './ui/common-tag/common-tag.component';
import { RoomTagComponent } from './ui/room-tag/room-tag.component';
import { RoomTagsComponent } from './ui/room-tags/room-tags.component';
import { RoomTipComponent } from './ui/room-tip/room-tip.component';
import { RoomDialogComponent } from './pages/room/room-dialog/room-dialog.component';
import { ChatComponent } from './pages/chat/chat/chat.component';
import { RoomMemberViewComponent } from './ui/room-member-view/room-member-view.component';
import { BlockDialogComponent } from './dialog/block-dialog/block-dialog.component';
import { CommonDialogComponent } from './dialog/common-dialog/common-dialog.component';
import { ManageRoomInfoComponent } from './dialog/manage-room-info/manage-room-info.component';
import {AdsenseModule} from "ng2-adsense";
import { WishlistComponent } from './pages/wishlist/wishlist.component';
import { WishlistDetailComponent } from './pages/wishlist/wishlist-detail/wishlist-detail.component';
import { WishlistItemComponent } from './pages/wishlist/wishlist-item/wishlist-item.component';
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import { WishlistEditDialogComponent } from './pages/wishlist/wishlist-edit-dialog/wishlist-edit-dialog.component';
import { LoadingOverlayComponent } from './ui/loading-overlay/loading-overlay.component';
import { WishItemComponent } from './pages/wishlist/wish-item/wish-item.component';
import { CatalogScannerComponent } from './pages/catalog-scanner/catalog-scanner.component';

// registerLocaleData(localeFr, 'fr-FR');
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    SignInDialogComponent,
    SignUpDialogComponent,
    MyMatInputComponent,
    DartToTsComponent,
    ProfileComponent,
    TosComponent,
    PpComponent,
    HomeComponent,
    MarketComponent,
    RoomComponent,
    ItemCardContainerComponent,
    CritterpediaComponent,
    CollectedIconComponent,
    LoadingComponent,
    LoadingOverlayComponent,
    // BaseItemListComponent,
    CommonItemListComponent,
    FossilComponent,
    FurnitureComponent,
    SongComponent,
    VillagerComponent,
    FooterComponent,
    ChecklistComponent,
    CritterpediaDetailComponent,
    CritterpediaDialogComponent,
    DartToTypescriptToolComponent,
    RoomItemViewComponent,
    UserProfileRowComponent,
    CommonTagComponent,
    RoomTagComponent,
    RoomTagsComponent,
    RoomTipComponent,
    RoomDialogComponent,
    ChatComponent,
    RoomMemberViewComponent,
    BlockDialogComponent,
    CommonDialogComponent,
    ManageRoomInfoComponent,
    WishlistComponent,
    WishlistDetailComponent,
    WishlistItemComponent,
    WishlistEditDialogComponent,
    LoadingOverlayComponent,
    WishItemComponent,
    CatalogScannerComponent
  ],
  imports: [
    AdsenseModule.forRoot({
      adClient: 'ca-pub-3459399242895554',
      adSlot: 1562127059,
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // LocalizeRouterModule.forRoot(routes),
    // LocalizeRouterModule.forRoot(routes, {
    //   parser: {
    //     provide: LocalizeParser,
    //     useFactory: (translate, location, settings, http) =>
    //       new LocalizeRouterHttpLoader(translate, location, settings, http),
    //     deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
    //   }
    // }),
    // LocalizeRouterModule.forRoot(routes, {
    //   parser: {
    //     provide: LocalizeParser,
    //     useFactory: (translate, location, settings, http) =>
    //       new LocalizeRouterHttpLoader(translate, location, settings, http),
    //     deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
    //   }
    // }),
    // RouterModule.forRoot(routes),
    BrowserAnimationsModule,
    MatCardModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatListModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatDialogModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatDividerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatGridListModule,
    MatSidenavModule,
    ReactiveFormsModule,
    MatTabsModule,

    AngularFireAuthModule,
    DeviceDetectorModule,
    ClipboardModule,
    MatRippleModule,
    MatSelectModule,
    ShareModule,
    FontAwesomeModule,
    MatPaginatorModule,
    InfiniteScrollModule
  ],
  providers: [APIService, ScreenTrackingService, UserTrackingService, AuthenticationService],
  bootstrap: [AppComponent]
})
export class AppModule {
}

platformBrowserDynamic().bootstrapModule(AppModule);
