<app-my-mat-input
  label="{{ 'title' | translate }}"
  type="text"
  [required]="true"
  [form_control]="editForm"
  [defaultValue]="wishlistName"
  placeholder="{{ 'title' | translate }}"
  (changed)="onChange($event)"
></app-my-mat-input>
<div style="text-align: right">
  <button mat-button color="primary" (click)="confirmClicked()">
    {{ "confirm" | translate }}
  </button>
  <button mat-button style="color: gray" (click)="onClose()">
    {{ "cancel" | translate }}
  </button>
</div>
