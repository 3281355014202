import {NameValuePair} from "./NameValuePair";

export class FilterPair {
  title: string;
  pairs: NameValuePair[];
  selected: NameValuePair;

  constructor(title: string, pairs: NameValuePair[], selected: number = null) {
    this.title = title;
    this.pairs = pairs;
    if (selected == null) {
      this.selected = pairs[0];
    } else {
      this.selected = pairs[selected];
    }

  }
}
