<mat-card style="margin-bottom: 8px; width: 100%" class="wish-item">
  <div class="wish-item-content">
    <img class="wish-item-img" src="{{ data?.item?.image }}" />
    <div class="wish-item-desc">
      <span class="wish-item-name">
        {{ data.item.getName() }}
      </span>
      <span class="wish-item-cate">
        {{ data.item.category }}
      </span>
      <span class="wish-item-cate" *ngIf="data.isDiy()">
        [DIY]
      </span>
    </div>
  </div>
  <div class="wish-item-actions">
    <div
      class="wish-item-action-btn"
      (click)="
        onAmountChange.emit({ itemId: data.item_id, amount: data.amount + 1 })
      "
    >
      +
    </div>
    <span class="wish-item-amount">{{ data.amount }}</span>
    <div
      class="wish-item-action-btn"
      (click)="
        onAmountChange.emit({ itemId: data.item_id, amount: data.amount - 1 })
      "
    >
      -
    </div>
  </div>
</mat-card>
