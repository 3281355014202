import {Component, Input, OnInit} from '@angular/core';
import {Room, RoomTag} from "../../models/Room";

@Component({
  selector: 'app-room-tags',
  templateUrl: './room-tags.component.html',
  styleUrls: ['./room-tags.component.scss']
})
export class RoomTagsComponent implements OnInit {
  @Input() room: Room;

  // tags: RoomTag[];

  constructor() {
  }

  ngOnInit(): void {
    // this.tags = [new RoomTag({})].concat(this.room.tags);
  }

  getRoomTypeColor() {
    return this.room.type == Room.TYPE_FREE ? '#42A5F5' : '#EF5350';
  }

}
