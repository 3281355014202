import {BaseClass} from "./BaseClass";
import {DonatedObject} from "./DonatedObject";

export class Profile extends BaseClass {
  id: number;
  timezone: number;
  firebase_uid: string;
  email: string;
  photoUrl: string;
  name: string;
  code: string;
  des: string;
  specialty: string;
  island_name: string;
  langCode: string;
  sw_code: string;
  is_searchable: boolean;
  access_token: string;
  is_removed_ads: boolean;
  last_backup: string;
  hemisphere: string;
  be_friend_like: number;
  room_like: number;
  trade_like: number;
  following: boolean;
  collectedItems: DonatedObject[] = [];
  checklists: Array<[string, any]> = [];
  turnipPrices: Array<[string, any]> = [];


  constructor(data: Profile) {
    super(data);
    if (data.collectedItems != null) {
      // console.log(data.collectedItems.length);
      this.collectedItems = [];
      for (let c of data.collectedItems) {
        this.collectedItems.push(c);
      }
    }
  }

  getLikeCount(): number {
    return this.be_friend_like + this.room_like + this.trade_like;
  }

  getLanguage(): string {
    switch (this.langCode) {
      case 'de':
        return 'Deutsch';
      case 'en':
        return 'English';
      case 'es':
        return 'español';
      case 'fr':
        return 'français';
      case 'it':
        return 'Italiano';
      case 'ja':
        return '日本語';
      case 'ko':
        return '한국어';
      case 'ru':
        return 'русский язык';
      case 'zh_TW':
        return '繁體中文';
      case 'zh_CN':
        return '简体中文';
    }
    return '';
  }

  getTimezone() {
    return this.timezone >= 0 ? `+${this.timezone}` : this.timezone;
  }
}

export class UserLike extends BaseClass {

  static LIKE_TYPE_ROOM = 'room';
  static LIKE_TYPE_TRADE = 'trade';
  static LIKE_TYPE_BE_FRIEND = 'be_friend';

  id: number;
  uid: number;
  related_id: number;
  related_type: string;

  constructor(data: UserLike) {
    super(data);
  }
}
