import {Injectable} from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize, retry, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Profile} from '../models/User';
import {AuthenticationService} from './AuthenticationService';
import {BaseSimpleModel} from '../models/BaseSimpleModel';
import {Animal} from '../models/Animal';
import {Fossil} from '../models/Fossil';
import {Item} from '../models/Item';
import {KKCD} from '../models/KKCD';
import {Villager} from '../models/Villager';
import {FurnitureComponent} from '../pages/furniture/furniture.component';
import {DonatedObject} from '../models/DonatedObject';
import {Room, RoomParticipant} from '../models/Room';
import {ChatGroup, ChatMessage} from '../models/Chat';
import {WishList} from '../models/WishList';
import * as moment from 'moment';
import {CatalogScanner} from "../models/CatalogScanner";

@Injectable()
export class APIService {
  configUrl = environment.url;

  constructor(
    private http: HttpClient,
    private deviceService: DeviceDetectorService,
    private auth: AuthenticationService
  ) {
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  private _get(endPoint: string, params?): any {
    let url = this.configUrl + endPoint;
    const mHttpOptions = {
      params,
    };
    console.log('Connecting-get: ' + url, mHttpOptions);
    return this.http.get<any>(url, mHttpOptions).pipe(
      tap((res) => this.handleHttpResponse(res)),
      catchError(this.handleError) // then handle the error
    );
  }

  private _post(endPoint: string, body, params?): any {
    let url = this.configUrl + endPoint;
    const mHttpOptions = {
      params,
    };
    console.log(url);
    console.log(params.toString());
    console.log('connecting-post:' + url, mHttpOptions);
    console.log(body);
    return this.http.post<any>(url, body, mHttpOptions).pipe(
      tap((res) => this.handleHttpResponse(res)),
      catchError(this.handleError) // then handle the error
    );
  }

  private _put(endPoint: string, body, params?): any {
    let url = this.configUrl + endPoint;
    const mHttpOptions = {
      params,
    };
    console.log(url);
    console.log(params.toString());
    console.log('connecting-post:' + url, mHttpOptions);
    console.log(body);
    return this.http.put<any>(url, body, mHttpOptions).pipe(
      tap((res) => this.handleHttpResponse(res)),
      catchError(this.handleError) // then handle the error
    );
  }

  handleHttpResponse(res) {
    console.log(res);
    // var json = res.json();
    const t = 'result';

    if (res != null && res.hasOwnProperty(t)) {
      switch (res[t]) {
        case -1:
          // invalid token
          this.auth.signOut();
          return;
        case -2:
          // token expired
          // this._service.logout("Login error. Please login again");
          return;
        default:
          return res;
      }
    }
    return res;
  }

  getAppVersion() {
    let params = [];
    //
    params['per-page'] = '1';
    // @ts-ignore
    params['sort'] = '-create_at';
    return this._get('app-version', params);
  }

  async checkCredentials() {
    console.log('checkCredentials');
    try {
      let user: Profile = await this.checkUser().toPromise();
      if (user == null || typeof user == 'string') {
        console.log(user);
        this.auth.removeUserInfo();
        return false;
      }
      this.auth.updateUserInfo(user);
      return true;
    } catch (e) {
      console.log(e);
      this.auth.removeUserInfo();
      return false;
    }
  }

  signUp(fb_token: string): Observable<Profile> {
    let params = [];
    params['fb_token'] = fb_token;

    let body = {
      os: this.deviceService.os,
      os_version: this.deviceService.os_version,
      device: this.deviceService.device,
      browser: this.deviceService.browser,
      browser_version: this.deviceService.browser_version,
      userAgent: this.deviceService.userAgent,
      timezone: this.deviceService.userAgent,
      langCode: this.deviceService.userAgent,
    };
    return this._post('user/sign-up1', body, params);
  }

  signIn(fb_token: string): Observable<Profile> {
    let params = [];
    params['os'] = this.deviceService.os;
    params['os_version'] = this.deviceService.os_version;
    params['device'] = this.deviceService.device;
    params['browser'] = this.deviceService.browser;
    params['browser_version'] = this.deviceService.browser_version;
    params['userAgent'] = this.deviceService.userAgent;

    params['timezone'] = this.deviceService.userAgent;
    params['langCode'] = this.deviceService.userAgent;
    params['fb_token'] = fb_token;
    return this._get('user/sign-in', params);
  }

  updateCollected(collected: DonatedObject): Observable<DonatedObject> {
    let params = [];
    params['token'] = this.auth.getToken();

    console.log(params);
    let body = {
      donateType: collected.collected_key,
      objectId: collected.item_id,
      variation: collected.variation,
      amount: collected.amount,
      isDeleted: collected.isDeleted,
    };
    //
    // // body['donateType'] = collected.collected_key;
    // //
    // // // body['item_name'] = collected.item_name;
    // // body['objectId'] = collected.item_id;
    // // body['variation'] = collected.variation;
    // // body['amount'] = collected.amount;
    // // body['isDeleted'] = collected.isDeleted;
    // console.log(body);
    return this._post('user/collected', body, params);
  }

  // getUserByCode():Observable<Profile>{
  //
  // }

  getUser($id, $code): Observable<Profile> {
    let params = [];
    params['uid'] = $id;
    params['code'] = $code;
    params['token'] = this.auth.getToken() ?? '';
    return this._get('user/profile', params);
  }

  checkUser(): Observable<Profile> {
    let params = [];
    params['id'] = this.auth.getUid() ?? '';
    params['token'] = this.auth.getToken() ?? '';
    return this._get('user/check', params);
  }

  loadCatalogScanner(code: string, langCode: string): Observable<CatalogScanner> {
    let params = [];
    params['code'] = code;
    params['langCode'] = langCode;
    return this._get('item/catalog-scanner', params);
  }

  getAnimals(type, uid = '', collected_key = ''): Observable<Animal[]> {
    let params = [];
    params['type'] = type;
    params['collected_key'] = collected_key;
    return this.getCommonList<Animal>('animals', uid, params, 80);
  }

  getAnimal(aid): Observable<Animal> {
    return this.getCommonObject<Animal>('animals/' + aid);
  }

  getKKCD(uid: string = '', collected_key = ''): Observable<KKCD[]> {
    let params = [];
    params['collected_key'] = collected_key;
    return this.getCommonList<KKCD>('kk-records', uid, params);
  }

  getVillagers(
    uid: string = '',
    page = 0,
    pageSize = 40,
    collected_key = ''
  ): Observable<SerializerItem<Villager>> {
    let params = [];
    params['collected_key'] = collected_key;
    return this.getCommonSerializerList<Villager>(
      'villagers',
      page,
      pageSize,
      params
    );
  }

  getFossils(uid: string = '', collected_key = ''): Observable<Fossil[]> {
    let params = [];
    params['collected_key'] = collected_key;
    return this.getCommonList<Fossil>('fossils', uid, params);
  }

  getFurniture(
    uid: string = '',
    category = 'furniture',
    page = 0,
    pageSize = 40,
    customFilter = ''
  ): Observable<SerializerItem<Item>> {
    let params = [];
    params['category'] = category;
    params['collected_key'] = customFilter;
    return this.getCommonSerializerList<Item>('items', page, pageSize, params);
  }

  //TODO: keep this function name and convert those code to ts
  //
  //   Future<Map<String, dynamic>> smartBackupDataCollectedFromOnline(
  //     DonatedObject donatedObject) async {
  //   Map<String, dynamic> tmpJson = donatedObject.toMap();
  //   // debugPrint(tmpJson.toString());
  //   var body = jsonEncode(tmpJson);
  //   String token = await SharedPreferencesHelper.getAccessToken();
  //   String tokenString = 'token=$token';
  //   try {
  //   Map<String, dynamic> tmp = await postJson(
  //     'user/collected?$tokenString',
  //     body,
  //   );
  //   return tmp;
  // } catch (e) {
  //   debugPrint(e.toString());
  //   return null;
  // }
  // }

  smartBackupDataCollectedFromOnline(
    donatedObject: DonatedObject,
    isDelete = false
  ): Observable<any> {
    let token = `token=${this.auth.getToken()}`;
    donatedObject.isDeleted = isDelete ? 1 : 0;
    donatedObject.last_edit = moment()
      .utcOffset(8)
      .format('YYYY-MM-DD HH:mm:ss');

    let body = JSON.stringify(donatedObject); //TODO:
    let params = [];
    return this._post(`user/collected?${token}`, body, params);
  }

  getWishlists(): Observable<WishList[]> {
    let token = this.auth.getToken();
    return this._get('wishlists?token=' + token);
  }

  createWishlist(wishList: WishList): Observable<WishList> {
    let params = [];
    params['token'] = this.auth.getToken() ?? '';

    let body = JSON.stringify(wishList); //TODO:
    return this._post('wishlists', body, params);
  }

  updateWishlist(wishList: WishList): Observable<WishList> {
    let params = [];
    params['token'] = this.auth.getToken() ?? '';
    let body = JSON.stringify(wishList); //TODO:
    return this._post('user/wishlist', body, params);
  }

  deleteWishlist(wishList: WishList): Observable<WishList> {
    let params = [];
    params['token'] = this.auth.getToken() ?? '';
    let body = {
      ...wishList,
      isDeleted: 1,
    };

    return this._post('user/wishlist', JSON.stringify(body), params);
  }

  getWishlistInfo(wid: string): Observable<WishList> {
    return this._get('wishlists/wid/' + wid);
  }

  getWishlistOwner(wid: string): Observable<Profile> {
    return this._get('wishlists/owner/' + wid);
  }

  getWishlistDetail(
    userCode: string,
    wisthlistCode: string,
    diy_recipe: string = '',
    orderBy: string = '',
    page: number = 0,
    perPage: number = 999,
    collectedKey: string = ''
  ): Observable<SerializerItem<DonatedObject>> {
    let url = '';

    if (wisthlistCode != null) {
      // load by wishlist
      url = `wishlists/${wisthlistCode}`;
    } else {
      // signed in, load by default list
      url = `wishlists/u${userCode}`;
    }

    let extra = [];
    extra['collectedKey'] = collectedKey;
    // extra['collectedKey'] = '12';
    extra['orderBy'] = orderBy;
    extra['diy'] = diy_recipe;
    return this.getCommonSerializerList<DonatedObject>(
      url,
      page,
      perPage,
      extra
    );
  }

  // Chat
  getChatGroup(chatGroupId): Observable<ChatGroup> {
    let params = [];
    params['token'] = this.auth.getToken() ?? '';
    return this._get('chats/' + chatGroupId, params);
  }

  getChatMessages(chat_group_id: number, time = ''): Observable<ChatMessage[]> {
    let params = [];
    params['token'] = this.auth.getToken() ?? '';
    params['chat_group_id'] = chat_group_id;
    params['time'] = time;
    return this._get('chat/messages', params);
  }

  sendMessage(chat_group_id: number, msg: string): Observable<ChatMessage> {
    let params = [];
    params['token'] = this.auth.getToken() ?? '';
    params['chat_group_id'] = chat_group_id;

    let body = {
      text: msg,
    };
    return this._post('chat/messages?', body, params);
  }

  ////////////Room
  getRooms(
    selectedLang = '',
    type = '',
    keyword = '',
    tag = '',
    page = 0,
    perPage = 40,
    joined: string = '',
    turnip_price = null,
    tip_requested = null
  ): Observable<SerializerItem<Room>> {
    let params = [];
    params['keyword'] = keyword;
    params['langCode'] = selectedLang;
    params['type'] = type;
    params['tag'] = tag;
    params['page'] = page;
    params['per-page'] = perPage;
    if (turnip_price != null) {
      params['turnip_price'] = turnip_price;
    }
    if (tip_requested != null) {
      params['tip_requested'] = tip_requested;
    }

    if (joined != null && joined.length > 0) {
      params['joined'] = joined;
    }

    return this.getCommonSerializerList<Room>('room', page, perPage, params);
  }

  getMyHostingRoom(): Observable<Room> {
    let params = [];
    params['token'] = this.auth.getToken() ?? '';

    return this._get('room/my-hosting-room', params);
  }

  closeRoom(roomId: number): Observable<Room> {
    let params = [];
    params['token'] = this.auth.getToken() ?? '';
    params['roomId'] = roomId;

    return this._get('room/close', params);
  }

  createRoom(room: Room): Observable<Room> {
    let params = [];
    params['token'] = this.auth.getToken() ?? '';

    return this._post('rooms', room, params);
  }

  updateRoom(room: Room): Observable<Room> {
    let params = [];
    params['token'] = this.auth.getToken() ?? '';

    return this._put('rooms/' + room.id, room, params);
  }

  getRoomMemberList(
    roomId: number,
    status = ''
  ): Observable<RoomParticipant[]> {
    let params = [];
    params['token'] = this.auth.getToken() ?? '';
    params['roomId'] = roomId;
    params['status'] = status;
    return this._get('room/member-list', params);
  }

  fetchRoom(roomId: number, uid = ''): Observable<Room> {
    let params = [];
    params['roomId'] = roomId;
    params['uid'] = uid;
    params['token'] = this.auth.getToken() ?? '';

    return this._get('room/fetch-room-info', params);
  }

  joinRoom(roomId: number): Observable<RoomParticipant> {
    let params = [];
    params['roomId'] = roomId;
    params['token'] = this.auth.getToken() ?? '';

    return this._get('room/join', params);
  }

  inviteParticipant(
    roomId: number,
    participantId: number
  ): Observable<RoomParticipant> {
    return this._changeRoomParticipantStatusByHost(
      roomId,
      participantId,
      'invite'
    );
  }

  kickParticipant(
    roomId: number,
    participantId: number
  ): Observable<RoomParticipant> {
    return this._changeRoomParticipantStatusByHost(
      roomId,
      participantId,
      'kick'
    );
  }

  _changeRoomParticipantStatusByHost(
    roomId: number,
    participantId: number,
    status: string
  ): Observable<RoomParticipant> {
    let params = [];
    params['roomId'] = roomId;
    params['participantId'] = participantId;
    params['token'] = this.auth.getToken() ?? '';

    return this._get('room/' + status, params);
  }

  leaveRoom(roomId): Observable<RoomParticipant> {
    let params = [];
    params['roomId'] = roomId;
    params['token'] = this.auth.getToken() ?? '';
    return this._get('room/leave', params);
  }

  reportRoom(
    room_id: number,
    reason: string,
    note: string,
    defendant_id: number = null
  ): Observable<boolean> {
    let params = [];
    params['room_id'] = room_id;
    params['token'] = this.auth.getToken() ?? '';

    let body = {
      room_id: room_id,
      reason: reason,
      defendant_id: defendant_id,
    };
    return this._post('room/report', body, params);
  }

  getCommonList<T extends BaseSimpleModel>(
    endPoint,
    uid = '',
    paramsExtra = [],
    perPage = 80
  ): Observable<T[]> {
    let params = paramsExtra;
    // console.log(params);
    let langCode = localStorage.getItem('langCode');
    if (langCode == 'zh') {
      langCode = 'zh_TW';
    } else if (langCode == 'zh-CN') {
      langCode = 'zh_CN';
    }
    params['langCode'] = langCode;
    params['token'] = this.auth.getToken() ?? '';
    params['uid'] = uid;
    params['per-page'] = perPage;
    // console.log(params);
    return this._get(endPoint, params);
  }

  getCommonSerializerList<T>(
    endPoint,
    page = 0,
    perPage = 40,
    paramsExtra = [],
    uid = ''
  ): Observable<SerializerItem<T>> {
    let params = paramsExtra;

    if (params['langCode'] == null) {
      let langCode = localStorage.getItem('langCode');
      if (langCode != null) {
        if (langCode == 'zh') {
          langCode = 'zh_TW';
        } else if (langCode == 'zh-CN') {
          langCode = 'zh_CN';
        }
        params['langCode'] = langCode;
      }
    }

    params['token'] = this.auth.getToken() ?? '';
    params['uid'] = uid;
    params['serializer'] = 1;
    params['per-page'] = perPage;
    params['page'] = page;
    // console.log(params);
    return this._get(endPoint, params);
  }

  getCommonObject<T extends BaseSimpleModel>(
    endPoint,
    paramsExtra = []
  ): Observable<T> {
    let params = paramsExtra;
    let langCode = localStorage.getItem('langCode');
    if (langCode == 'zh') {
      langCode = 'zh_TW';
    } else if (langCode == 'zh-CN') {
      langCode = 'zh_CN';
    }
    params['langCode'] = langCode;
    params['uid'] = this.auth.getUid() ?? '';
    return this._get(endPoint, params);
  }

  // getConfig() {
  //   return this.http.get(this.configUrl)
  //     .pipe(
  //       // retry(3), // retry a failed request up to 3 times
  //       catchError(this.handleError) // then handle the error
  //     );
  // }

  // getConfig_1() {
  //   return this.http.get(this.configUrl);
  // }
  //
  // getConfig_2() {
  //   // now returns an Observable of Config
  //   return this.http.get<Config>(this.configUrl);
  // }
  //
  // getConfig_3() {
  //   return this.http.get<Config>(this.configUrl)
  //     .pipe(
  //       catchError(this.handleError)
  //     );
  // }
  //
  // getConfigResponse(): Observable<HttpResponse<Config>> {
  //   return this.http.get<Config>(
  //     this.configUrl, { observe: 'response' });
  // }
  //

  //
  // makeIntentionalError() {
  //   return this.http.get('not/a/real/url')
  //     .pipe(
  //       catchError(this.handleError)
  //     );
  // }
}

class SerializerItem<T> {
  items: T[];
  _meta: SerializerMeta;
}

class SerializerMeta {
  totalCount: number;
  pageCount: number;
  currentPage: number;
  perPage: number;
}
