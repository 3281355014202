import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationService} from "../../service/AuthenticationService";
import {APIService} from "../../service/api.service";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {switchMap} from "rxjs/operators";
import {Profile} from "../../models/User";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {DonatedObject} from "../../models/DonatedObject";
import {Item} from "../../models/Item";
import {Fossil} from "../../models/Fossil";
import {KKCD} from "../../models/KKCD";
import {Villager} from "../../models/Villager";
import {Meta} from "@angular/platform-browser";


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  SPECIAL_NUMBER = 24970;
  isLoading = true;
  userId: string = '';
  currentSection: string = '';
  profile: Profile;
  collectedAnimal: DonatedObject[] = [];
  collectedFossil: DonatedObject[] = [];
  collectedFurniture: DonatedObject[] = [];
  collectedSong: DonatedObject[] = [];
  collectedVillager: DonatedObject[] = [];

  constructor(public service: AuthenticationService, public apiService: APIService, public translate: TranslateService,
              public dialog: MatDialog, private router: Router, private route: ActivatedRoute, private meta: Meta) {
    // console.log(this.router.url);
    this.meta.updateTag({property: 'og:title', content: 'asdasd'});

  }

  ngOnInit(): void {
    // this.meta.addTag({name: 'og:title', content: 'test'})
    // this.meta.addTag({name: 'og:description', content: 'this.profile.des'})
    // this.meta.addTag({name: 'og:image', content: 'this.profile.photoUrl'})
    // this.meta.addTag({property: 'og:title', content: 'test'})
    // this.meta.addTag({property: 'og:description', content: 'this.profile.des'})
    // this.meta.addTag({property: 'og:image', content: 'this.profile.photoUrl'})

    this.route.params.subscribe(params => {


      let uid = Number(params['id']);
      let uCode = Number(params['uCode']);
      // this.userId = uid.toString();
      if (isNaN(uid)) {

        this.userId = this.service.getUid();
      } else {
        this.userId = (uid - this.SPECIAL_NUMBER).toString();
      }
      // console.log(this.userId);

      this.currentSection = params['section'];
      this.apiService.getUser(this.userId, uCode).subscribe(p => {
        this.profile = new Profile(p);
        this.meta.updateTag({property: 'og:title', content: this.profile.name})
        this.meta.updateTag({property: 'og:description', content: this.profile.des})
        this.meta.updateTag({property: 'og:image', content: this.profile.photoUrl})
        for (let c of this.profile.collectedItems) {
          switch (c['collected_key']) {
            case 'fish':
            case 'insect':
              this.collectedAnimal.push(c);
              break;
            case Fossil.KEY:
              this.collectedFossil.push(c);
              break;
            case Item.KEY_OWN_ITEM:
              this.collectedFurniture.push(c);
              break;
            case KKCD.KEY:
              this.collectedSong.push(c);
              break;
            case Villager.KEY_DONATE:
              this.collectedVillager.push(c);
              break;
          }
        }
      }, null, () => {
        this.isLoading = false;
      });
    });
  }

  test(event) {
    console.log(event);
  }

}
