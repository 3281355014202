import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-room-tag',
  templateUrl: './room-tag.component.html',
  styleUrls: ['./room-tag.component.scss']
})
export class RoomTagComponent implements OnInit {
  @Input() title;
  @Input() color;
  @Input() textColor;

  constructor() {
  }

  ngOnInit(): void {
  }

  getStyle() {
    return {
      'color': this.textColor ?? '',
      'background': this.color ?? '',
    };
  }

}
