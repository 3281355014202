import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeeplinkService {

  constructor() {
  }

  deeplink(url:string) {
    let ua = navigator.userAgent.toLowerCase();
    let isAndroid = ua.indexOf("android") > -1; // android check
    let isIphone = ua.indexOf("iphone") > -1; // ios check
    if (isIphone == true) {
      let app = {
        launchApp: function () {
          // setTimeout(function () {
          //   window.location.href = "https://apps.apple.com/us/app/animal-guide/id1507549116?ls=1";
          // }, 25);
          window.location.href = "agacnh://animalguide.app"+url; //which page to open(now from mobile, check its authorization)
        },
        // openWebApp: function () {
        //   window.location.href = "https://apps.apple.com/us/app/animal-guide/id1507549116?ls=1";
        // }
      };
      app.launchApp();
    } else if (isAndroid == true) {
      let app = {
        launchApp: function () {
          window.location.replace("agacnh://animalguide.app"+url); //which page to open(now from mobile, check its authorization)
          // setTimeout(this.openWebApp, 500);
        },
        // openWebApp: function () {
        //   window.location.href = "https://play.google.com/store/apps/details?id=com.playgroup.animalcrossguide";
        // }
      };
      app.launchApp();
    } else {
      //navigate to website url
    }
  }
}
