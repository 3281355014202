import {BaseSimpleModel} from "./BaseSimpleModel";
import {DonatedObject} from "./DonatedObject";

export class KKCD extends BaseSimpleModel {
  buyPrice: number;
  static KEY: string = 'kkcd';

  constructor(data: KKCD, donatedObject?: DonatedObject) {
    super(data);
    this.donatedObject = donatedObject;
  }
}
