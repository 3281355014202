import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Room} from "../../../models/Room";
import {AuthenticationService} from "../../../service/AuthenticationService";
import {CommonDialogComponent} from "../../../dialog/common-dialog/common-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {ManageRoomInfoComponent} from "../../../dialog/manage-room-info/manage-room-info.component";

@Component({
  selector: 'app-room-item-view',
  templateUrl: './room-item-view.component.html',
  styleUrls: ['./room-item-view.component.scss']
})
export class RoomItemViewComponent implements OnInit {
  @Input() room: Room;
  @Output() leaveClicked = new EventEmitter<void>();
  @Output() updateSuccess = new EventEmitter<void>();
  @Output() closeRoom = new EventEmitter<void>();
  uid: number;

  constructor(public service: AuthenticationService, public dialog: MatDialog) {
    this.uid = +this.service.getUid();
  }

  ngOnInit(): void {

  }

  closeRoomClicked(){
    const dialogRef = this.dialog.open(CommonDialogComponent, {
      width: '500px',
      data: {
        title: 'confirm_to_close',
        is_show_cancel: true,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        // update TODO:
        this.closeRoom.emit();
      }
    });
  }

  openEditDialog() {
    const dialogRef = this.dialog.open(ManageRoomInfoComponent, {
      width: '500px',
      data: {
        room: new Room(this.room),
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        // update TODO:
        this.updateSuccess.emit();
      }
    });
  }

  leave() {
    const dialogRef = this.dialog.open(CommonDialogComponent, {
      width: '500px',
      data: {
        title: 'confirm_to_leave',
        is_show_cancel: true,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        // leave
        this.leaveClicked.emit();
      }
    });

  }

}
