<mat-drawer-container class="{{isDark?'unicorn-dark-theme':''}} mat-app-background" autosize style="min-height: 100%">

  <!-- mobile drawer -->
  <mat-drawer #drawer mode="over" position="end">
    <mat-nav-list>
      <a routerLink="{{'/'+navItem.path}}" mat-list-item *ngFor="let navItem of navMenu;">
        {{ navItem.title | translate }}
      </a>
      <a routerLink="/wishlist" mat-list-item *ngIf="service.isSignedIn()">
        {{ 'wishlist' | translate }}
      </a>
      <a routerLink="{{'/profile'}}" mat-list-item *ngIf="service.isSignedIn()">
        {{ 'profile' | translate }}
      </a>
      <a mat-list-item *ngIf="service.isSignedIn()" (click)="signOut()">
        {{'sign_out' | translate}}
      </a>
      <a mat-list-item *ngIf="!service.isSignedIn()" (click)="openSignInDialog()">
        {{'sign_in' | translate}}
      </a>
      <a mat-list-item *ngIf="!service.isSignedIn()" (click)="openSignUpDialog()">
        {{'sign_up' | translate}}
      </a>
      <a mat-list-item>
        <button mat-button [matMenuTriggerFor]="menu_lang">
          <mat-icon>languages</mat-icon>
        </button>
      </a>
    </mat-nav-list>
  </mat-drawer>

  <!-- Toolbar -->
  <mat-toolbar>
    <img [src]="'assets/img/logo_circle.png'" style="height: 80%; margin-right: 14px;">
    <span>{{ 'animal_guide' | translate }}</span>
    <span class="example-spacer"></span>

    <!-- mobile -->
    <div class="nav-menu-mobile">
      <button mat-button (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>

    <!-- desktop -->
    <div class="nav-menu-desktop">

      <a routerLink="{{'/'+navItem.path}}" mat-button *ngFor="let navItem of navMenu;">
        {{ navItem.title | translate }}
      </a>

      <a routerLink="/wishlist" mat-button *ngIf="service.isSignedIn()">
        {{ 'wishlist' | translate }}
      </a>

      <a routerLink="/profile/{{service.getAdjustedUid()}}" mat-button *ngIf="service.isSignedIn()">
        {{ 'profile' | translate }}
      </a>
      <!-- sign out -->
      <a mat-button (click)="signOut()" *ngIf="service.isSignedIn()">
        {{'sign_out' | translate}}
      </a>
      <!--      &lt;!&ndash; settings &ndash;&gt;-->
      <button mat-button [matMenuTriggerFor]="menu" *ngIf="service.isSignedIn()">
        <mat-icon>settings</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item>
          <mat-slide-toggle (toggleChange)="changeTheme()">{{'dark_mode'|translate}}</mat-slide-toggle>
        </button>
      </mat-menu>

      <!-- sign in -->
      <a mat-button *ngIf="!service.isSignedIn()" (click)="openSignInDialog()">
        {{'sign_in' | translate}}
      </a>
      <!-- sign up -->
      <button mat-button mat-raised-button color="primary" *ngIf="!service.isSignedIn()" (click)="openSignUpDialog()">
        {{'sign_up' | translate}}
      </button>

      <!-- language-->
      <button mat-button [matMenuTriggerFor]="menu_lang">
        <mat-icon>languages</mat-icon>
      </button>
      <mat-menu #menu_lang="matMenu">
        <button mat-menu-item *ngFor="let s of supportedLangTitles;index as i;"
                (click)="changeLang(supportedLangValues[i])">{{s}}</button>
      </mat-menu>


    </div>

  </mat-toolbar>

  <!--  <div style="min-height: 80%;">-->
  <!--  <div *ngIf="!isDesktop">-->
  <!--    <mat-card style="display: flex;">-->
  <!--      <h3>{{'download_our_app'|translate}}</h3>-->
  <!--      <span class="example-spacer"></span>-->
  <!--      <button mat-raised-button color="primary">{{'download'|translate}}</button>-->
  <!--    </mat-card>-->
  <!--  </div>-->
  <div class="page-content">
    <router-outlet></router-outlet>
  </div>
  <!--  </div>-->
  <!--  <footer>-->
  <!--    <div>-->
  <!--      <div>{{'download'|translate}}</div>-->
  <!--      <a class="footer-download" href="https://apps.apple.com/us/app/animal-guide/id1507549116?ls=1"-->
  <!--         target="_blank">-->
  <!--        <img [src]="'assets/img/app_store.png'">-->
  <!--      </a>-->
  <!--      <a class="footer-download"-->
  <!--         href="https://play.google.com/store/apps/details?id=com.playgroup.animalcrossguide"-->
  <!--         target="_blank">-->
  <!--        <img [src]="'assets/img/play_store.png'">-->
  <!--      </a>-->
  <!--    </div>-->
  <!--    <div>*DISCLAIMER* This is an unofficial app for Animal crossing: new horizons. This app is not affiliated with the-->
  <!--      Animal-->
  <!--      crossing brand, Niantic, Animal crossing:new horizons, or Nintendo."-->
  <!--    </div>-->

  <!--    <div style="margin:0 auto;padding: 20px; display: table;">Copyright © 2020 {{ 'animal_guide' | translate }}-->
  <!--      | Powered by PlayGroup-->
  <!--    </div>-->
  <!--  </footer>-->
  <app-footer [isDark]="isDark"></app-footer>
  <div style="position: fixed;bottom: 0;left: 0;right: 0;">
    <button mat-raised-button style=" width: 100%;background-color:#baf4d0;color: #5D4037" (click)="openInApp()" >{{'open_in_app'|translate}}</button>
  </div>
</mat-drawer-container>

