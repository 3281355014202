import {Router} from "@angular/router";
import {Injectable} from "@angular/core";
import {Profile} from "../models/User";
import {APIService} from "./api.service";

@Injectable()
export class AuthenticationService {
  static KEY_UID = 'uid';
  static KEY_TOKEN = 'token';
  static KEY_CODE = 'code';
  static KEY_USERNAME = 'username';
  static KEY_ISLAND_NAME = 'islandName';
  static KEY_EMAIL = 'email';
  static KEY_IS_REMOVED_ADS = 'is_removed_ads';

  // uid', uid);
  // localStorage.setItem('token', token);
  // localStorage.setItem('username', username);
  // localStorage.setItem('islandName', islandName);
  // localStorage.setItem('email', email);
  // localStorage.setItem('is_removed_ads', is_removed_ads);

  constructor(private router: Router) {
  }


  getToken() {
    return localStorage.getItem(AuthenticationService.KEY_TOKEN);
  }

  SPECIAL_NUMBER = 24970;

  getAdjustedUid() {
    let uid = this.getUid();
    if (uid == null) {
      return '';
    }
    return +uid + this.SPECIAL_NUMBER;

  }

  getIslandName() {
    return localStorage.getItem(AuthenticationService.KEY_ISLAND_NAME);
  }

  getUserName() {
    return localStorage.getItem(AuthenticationService.KEY_USERNAME);
  }

  getUid() {
    return localStorage.getItem(AuthenticationService.KEY_UID);
  }

  get userCode() {
    return localStorage.getItem(AuthenticationService.KEY_CODE);
  }

  isSignedIn() {
    // console.log('isSignedIn');
    // console.log(localStorage.getItem(AuthenticationService.KEY_TOKEN))
    return localStorage.getItem(AuthenticationService.KEY_TOKEN) != null;
  }

  signOut() {
    this.removeUserInfo();
    this.router.navigate(['']);
  }


  signIn(profile: Profile) {
    this.updateUserInfo(profile);
  }

  removeUserInfo() {
    localStorage.removeItem(AuthenticationService.KEY_UID);
    localStorage.removeItem(AuthenticationService.KEY_TOKEN);
    localStorage.removeItem(AuthenticationService.KEY_CODE);
    localStorage.removeItem(AuthenticationService.KEY_USERNAME);
    localStorage.removeItem(AuthenticationService.KEY_ISLAND_NAME);
    localStorage.removeItem(AuthenticationService.KEY_EMAIL);
    localStorage.removeItem(AuthenticationService.KEY_IS_REMOVED_ADS);
  }

  updateUserInfo(profile: Profile) {
    localStorage.setItem(AuthenticationService.KEY_UID, profile.id.toString());
    localStorage.setItem(AuthenticationService.KEY_TOKEN, profile.access_token);
    localStorage.setItem(AuthenticationService.KEY_USERNAME, profile.name);
    localStorage.setItem(AuthenticationService.KEY_CODE, profile.code);
    localStorage.setItem(AuthenticationService.KEY_ISLAND_NAME, profile.island_name);
    localStorage.setItem(AuthenticationService.KEY_EMAIL, profile.email);
    localStorage.setItem(AuthenticationService.KEY_IS_REMOVED_ADS, profile.is_removed_ads.toString());
  }


  isAdmin() {
    return this.isSignedIn();
    // return localStorage.getItem("isAdmin") == "1";
  }

  //
  // loginAdmin(id, name, email, access_token, permission) {
  //   localStorage.setItem('id', id);
  //   localStorage.setItem('title', name);
  //   localStorage.setItem('email', email);
  //   localStorage.setItem('access_token', access_token);
  //   localStorage.setItem('permission', permission);
  //   // this._router.navigate([Constants.PATH_INDIVIDUALS]);
  // }


}
