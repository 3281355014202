<div>
  <app-user-profile-row [profile]="roomParticipant.user" style="display: inline-block"></app-user-profile-row>
  <span style="float: right;
    display: inline-block;">{{position}}</span>
  <div class="row" style="margin-top: 8px" *ngIf="isHost">
    <button mat-stroked-button style="color: #1976d2" class="col"
            (click)="inviteClicked()">{{'invite'|translate}}</button>
    <button mat-stroked-button color="accent" class="col" (click)="kickClicked()">{{'kick'|translate}}</button>
    <button mat-stroked-button color="warn" class="col" (click)="blockClicked()">{{'block'|translate}}</button>
  </div>
  <hr>
</div>

