<div>
  <button mat-button (click)="run()">Click</button>
  <button [cdkCopyToClipboard]="result">Copy to clipboard</button>
</div>
<mat-form-field class="example-full-width">
  <mat-label>Leave a comment</mat-label>
  <textarea matInput placeholder="Ex. It makes me feel..." [formControl]="tmp"></textarea>
</mat-form-field>
<div [innerHTML]="result">
</div>
