<div class="main-container" [ngStyle]="getStyle()">
  <div class="main-body" style="height: 100%;">

<!--    ads-->
<!--    <ng-adsense-->
<!--      [adClient]="'ca-pub-3459399242895554'"-->
<!--      [adSlot]="3846235969"-->
<!--      [display]="'block'"-->
<!--      [adFormat]="'auto'"-->
<!--      [className]="'adsbygoogle'"-->
<!--      [fullWidthResponsive]="true"-->
<!--    ></ng-adsense>-->

    <!-- error handling-->
    <mat-card *ngIf="unexpectedError">
      <div style="min-height: 400px;text-align: center;padding-top: 180px;">
        <span>{{'unexpected_error'|translate}}</span><br><br>
        <a routerLink="/rooms" mat-raised-button color="primary">{{'confirm'|translate}}</a>
      </div>
    </mat-card>


    <div *ngIf="!unexpectedError && _messages!=null" style="height: 100%" class="row">
      <!-- left col-->
      <div class="col-6" style="height: 100%;">
        <app-room-item-view style="display: block;height: 48%;overflow-y: auto" [room]="chat_group.room"
                            (leaveClicked)="leave()" (updateSuccess)="updateSuccess()" (closeRoom)="closeRoom()"></app-room-item-view>
        <br>
        <mat-card style="height: 48%;overflow-y: auto">
          <app-room-member-view *ngFor="let m of waitingList;let i = index" [roomParticipant]="m"
                                [roomName]="chat_group.room.name"
                                [isHost]="chat_group.isHost(uid)" [position]="i+1"></app-room-member-view>
        </mat-card>
      </div>

      <!-- right col-->
      <mat-card class="col-6" style="height: 100%;">
        <div style="overflow-y: auto;height: calc(100% - 40px)" id="chat_list">
          <div *ngFor="let m of reversedMessage; ">

            <!-- system-->
            <div *ngIf="m.type=='system'" class='chat-bubble'
                 style="margin-left: auto;margin-right:auto;display: table;"
                 [ngStyle]="{'background':systemMessageColor}">
              {{m.text}}
            </div>
            <!-- my message-->
            <div *ngIf="m.type!='system' && m.isMyMessage(uid)" class='chat-bubble'
                 style="margin-left: auto;" [ngStyle]="{'background':myBubbleColor}">
              {{m.text}}
              <br>
              <span class="chat-time">{{m.getTime()}}</span>
            </div>
            <!-- other message-->
            <div *ngIf="m.type!='system' && !m.isMyMessage(uid)" class='chat-bubble'
                 style="margin-right: auto;: auto;" [ngStyle]="{'background':systemMessageColor}">
              <span style="color: #EF6C00;">{{m.getSenderName()}}</span>
              <br>
              {{m.text}}
              <br>
              <span class="chat-time">{{m.getTime()}}</span>
            </div>
          </div>
        </div>
        <div style="height: 40px;">
          <input matInput style="height: 40px;width: calc(100% - 40px);border: 1px solid gray"
                 (keydown)="keypress($event);"
                 [disabled]="isSending" [value]="currentText"
                 (change)="valueChanged($event)">
          <div
            style="background: dodgerblue;border-radius:40px;cursor: pointer;width: 40px;padding: 8px;height: 40px;display: inline-block;"
            (click)="sendClick()">
            <mat-icon style="color: #fff">send</mat-icon>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
