<div class="header" mat-dialog-title>{{(room != null ? 'edit_room' : 'create_room')|translate}}</div>
<div mat-dialog-content>
  <div class="title">{{'room_information'|translate}}</div>
  <app-my-mat-input label="{{'room_name'|translate}}"
                    type="text"
                    (changed)="room.name = $event"
                    [form_control]="roomName"></app-my-mat-input>
  <div class="sub-title">{{roomTagFilter.title|translate}}</div>
  <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(value)]="room.type">
    <mat-button-toggle [value]="TYPE_FREE">{{'free'|translate}}</mat-button-toggle>
    <mat-button-toggle [value]="TYPE_TIPS_REQUIRED">{{'entrance_fee_required'|translate}}</mat-button-toggle>
  </mat-button-toggle-group>

  <div *ngIf="room.type==TYPE_TIPS_REQUIRED">
    <div class="sub-title">{{'entry_requirements'|translate}}</div>
    <app-my-mat-input label="{{'bell'|translate}}"
                      type="number"
                      [required]="false"
                      [defaultValue]="room.bell"
                      (changed)="room.bell = +$event"></app-my-mat-input>
    <app-my-mat-input label="{{'ticket'|translate}}"
                      type="number"
                      [required]="false"
                      [defaultValue]="room.ticket"
                      (changed)="room.ticket = +$event"></app-my-mat-input>
    <app-my-mat-input label="{{'other_tips'|translate}}"
                      type="text"
                      [required]="false"
                      [defaultValue]="room.entry_condition"
                      (changed)="room.entry_condition = $event"></app-my-mat-input>
  </div>

  <div class="sub-title">{{'room_tags'|translate}}</div>
  <app-room-tag style="cursor: pointer" *ngFor="let tag of roomTagFilter.pairs;let i = index" [title]="tag.name"
                [color]="selectedTag[i]?tagSelectedColor:tagUnselectedColor"
                (click)="tagClicked(i)"></app-room-tag>

  <div class="sub-title">{{howToLeaveFilter.title|translate}}</div>
  <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(value)]="room.how_to_leave">
    <mat-button-toggle [value]="leaveOption.value"
                       *ngFor="let leaveOption of howToLeaveFilter.pairs;">{{leaveOption.name|translate}}</mat-button-toggle>
  </mat-button-toggle-group>
  <br>
  <br>
  <app-my-mat-input label="{{'room_notes'|translate}}"
                    type="text"
                    [required]="false"
                    [defaultValue]="room.notes"
                    (changed)="room.notes = $event"></app-my-mat-input>
  <hr>
  <div class="title">{{'room_information'|translate}}</div>
  <app-my-mat-input label="{{'connect_code'|translate}}"
                    type="text"
                    (changed)="room.island_passcode = $event"
                    [form_control]="connectCode"></app-my-mat-input>
  <app-my-mat-input label="{{'islandName'|translate}}"
                    type="text"
                    (changed)="room.island_name = $event"
                    [form_control]="islandName"></app-my-mat-input>
  <app-my-mat-input label="{{'char_name'|translate}}"
                    type="text"
                    (changed)="room.c_name = $event"
                    [form_control]="characterName"></app-my-mat-input>
</div>
<div mat-dialog-actions>
  <button mat-button color="primary" (click)="confirmClicked()"
          style="margin-left: auto;">{{'confirm'|translate}}</button>
  <button mat-button style="color: gray">{{'cancel'|translate}}</button>
</div>
