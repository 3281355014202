import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Room, RoomParticipant} from "../../models/Room";
import {APIService} from "../../service/api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {CritterpediaDialogComponent} from "../../dialog/critterpedia-dialog/critterpedia-dialog.component";
import {CommonDialogComponent} from "../../dialog/common-dialog/common-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {BlockDialogComponent} from "../../dialog/block-dialog/block-dialog.component";

@Component({
  selector: 'app-room-member-view',
  templateUrl: './room-member-view.component.html',
  styleUrls: ['./room-member-view.component.scss']
})
export class RoomMemberViewComponent implements OnInit {
  @Input() roomName: string;
  @Input() roomParticipant: RoomParticipant;

  @Input() isHost: boolean = false;
  @Input() isRoomClosed: boolean = false;
  @Input() isGaryColor: boolean = false;
  @Input() position: number;
  @Output() clickListener = new EventEmitter<string>();
  isLoading: boolean = false;

  constructor(protected apiService: APIService, public _snackBar: MatSnackBar, public translate: TranslateService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  async inviteClicked() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    let roomParticipant: RoomParticipant = await this.apiService.inviteParticipant(
      this.roomParticipant.room_id, this.roomParticipant.id).toPromise();

    if (typeof roomParticipant != 'string') {
      this._snackBar.open(await this.translate.get('invited').toPromise(), null, {duration: 3000});
      this.clickListener.emit(Room.TYPE_ROOM_INVITED);
    } else {
      this._snackBar.open(await this.translate.get('unexpected_error').toPromise(), null, {duration: 3000});
    }
  }

  kickClicked() {
    const dialogRef = this.dialog.open(CommonDialogComponent, {
      width: '500px',
      data: {
        title: 'confirm_to_kick',
        is_show_cancel: true,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        // kicked
        this.confirmToKick();
      }
    });
  }

  async confirmToKick() {
    this.isLoading = true;
    let roomParticipant: RoomParticipant = await this.apiService.kickParticipant(
      this.roomParticipant.room_id, this.roomParticipant.id).toPromise();
    if (roomParticipant != null) {
      this._snackBar.open('kicked', null, {duration: 3000});
      if (this.clickListener != null) {
        this.clickListener.emit(Room.TYPE_ROOM_KICKED);
      }
    }
    this.isLoading = false;
  }

  blockClicked() {
    const dialogRef = this.dialog.open(BlockDialogComponent, {
      width: '500px',
      data: {
        room_id: this.roomParticipant.room_id,
        roomName: this.roomName,
        roomParticipant: this.roomParticipant,
      }
    });
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result != null) {
    //     // kicked
    //     this.confirmToBlock();
    //   }
    // });
  }

  // confirmToBlock() {
  //
  // }

}
