<app-loading-overlay *ngIf="isLoading"></app-loading-overlay>
<div
  class="main-container"
  infiniteScroll
  [infiniteScrollDistance]="0.1"
  (scrolled)="onScroll()"
  [immediateCheck]="false"
>
  <div class="main-body">
    <div class="page-title">
      <h1>{{ title | translate }}</h1>
      <div>
        <button
          mat-button
          class="text-btn"
          (click)="onOrderBy(orderByTypes.ID_DEC)"
        >
          {{ "ID" | translate }}
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        /
        <button
          mat-button
          class="text-btn"
          (click)="onOrderBy(orderByTypes.QUANTITY_DEC)"
        >
          {{ "quantity" | translate }}
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
      </div>
    </div>

    <mat-divider class="mb-2"></mat-divider>

    <span style="margin-right: 10px; display: inline-block">{{
      "diy_recipes" | translate
    }}</span>
    <mat-button-toggle-group
      name="fontStyle"
      aria-label="Font Style"
      style="max-width: 85%"
    >
      <mat-button-toggle
        value="all"
        [checked]="!onlyShowDiy"
        (change)="onDiyFilter(false)"
        >{{ "all" | translate }}</mat-button-toggle
      >
      <mat-button-toggle
        value="diy_only"
        [checked]="onlyShowDiy"
        (change)="onDiyFilter(true)"
        >{{ "yes" | translate }}</mat-button-toggle
      >
    </mat-button-toggle-group>

    <div class="custom-grid-list row">
      <app-wish-item
        class="col-sm-4 col-lg-3"
        *ngFor="let c of data; let i = index"
        [data]="c"
        (onAmountChange)="amountChangeClicked($event)"
      ></app-wish-item>
    </div>
    <mat-paginator
      [length]="length"
      [hidePageSize]="true"
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      *ngIf="pageSize != null"
      (page)="pageChange($event)"
      style="margin-top: 12px"
    >
    </mat-paginator>
  </div>
</div>
