<p>{{'block'|translate}} & {{'report'|translate}}</p>
<mat-form-field class="block-input">
  <mat-label>{{'room'|translate}}</mat-label>
  <input matInput value="{{roomName}}" [disabled]="true">
</mat-form-field>
<mat-form-field class="block-input" *ngIf="roomParticipant!=null">
  <mat-label>{{'defendant'|translate}}</mat-label>
  <input matInput value="{{roomParticipant.user.name}}" [disabled]="true">
</mat-form-field>
<mat-form-field class="block-input">
  <mat-label>{{'reason'|translate}}</mat-label>
  <input matInput value="{{reason}}" (change)="reason = $event.target.value">
</mat-form-field>
<!--</form>-->
<div style="text-align: right">
  <button mat-button color="primary" (click)="confirmClicked()">{{'block'|translate}} & {{'report'|translate}}</button>
  <button mat-button style="color: gray" (click)="onClose()">{{'cancel'|translate}}</button>
</div>

