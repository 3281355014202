import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../service/AuthenticationService';
import { APIService } from '../../../service/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ChatGroup, ChatMessage } from '../../../models/Chat';
import { query } from '@angular/animations';
import { DonatedObject } from '../../../models/DonatedObject';
import { Profile } from '../../../models/User';
import { WISHLIST_TYPE, WISHLIST_ORDER } from 'src/app/app.constant';
import { finalize } from 'rxjs/operators';
import { CommonDialogComponent } from 'src/app/dialog/common-dialog/common-dialog.component';

@Component({
  selector: 'app-wishlist-detail',
  templateUrl: './wishlist-detail.component.html',
  styleUrls: ['./wishlist-detail.component.scss'],
})
export class WishlistDetailComponent implements OnInit {
  wid: string;
  collectedKey: string;
  data: DonatedObject[];

  orderByTypes = WISHLIST_ORDER;
  orderBy = WISHLIST_ORDER.ID_DEC;
  onlyShowDiy = false;

  pageIndex = 0;
  length = 0;
  pageSize = 999;
  isLoading: boolean = true;
  title = '';
  user: Profile;

  constructor(
    private service: AuthenticationService,
    protected apiService: APIService,
    private route: ActivatedRoute,
    public _snackBar: MatSnackBar,
    private router: Router,
    public translate: TranslateService,
    public dialog: MatDialog
  ) {
    this.route.params.subscribe((params) => {
      this.wid = params['wid'];
      var userCode, wisthlistCode;
      if (this.wid.substr(0, 1) == 'u') {
        // default wishlist
        userCode = this.wid.substr(1);
      } else {
        // custom wishlist
        wisthlistCode = this.wid;
      }
      this.route.queryParams.subscribe((query) => {
        this.collectedKey = query['key'];
        this.onlyShowDiy = query['diy']==1;
        if (this.wid.substr(0, 1) == 'u') {
          this.title = this.collectedKey;
        } else {
          this.loadWishlist(wisthlistCode);
        }
        this.loadWishlistDetail(userCode);
        this.getWishlistOwner(this.wid);
      });
    });
  }

  getWishlistOwner(wid: string) {
    this.apiService.getWishlistOwner(wid).subscribe((w) => {
      this.user = new Profile(w);
    });
  }

  loadWishlist(wid: string) {
    this.apiService.getWishlistInfo(wid).subscribe((w) => {
      this.title = w.title;
    });
  }

  loadWishlistDetail(userCode: string) {
    const wisthlistCode = this.wid;
    this.isLoading = true;
    this.apiService
      .getWishlistDetail(
        userCode,
        wisthlistCode,
        this.onlyShowDiy ? '1' : '0',
        this.orderBy,
        this.pageIndex,
        this.pageSize,
        this.collectedKey
      )
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((wishlistItems) => {
        try {
          this.length = wishlistItems._meta.totalCount;
          console.log(this.length, this.pageIndex);
          this.data = [];
          for (let i of wishlistItems.items) {
            this.data.push(new DonatedObject(i));
          }
        } catch (e) {
          // error
          console.log(e);
        }
      });
  }

  ngOnInit(): void {}

  showConfirmDeleteDialog(donatedObject: DonatedObject) {
    const dialogRef = this.dialog.open(CommonDialogComponent, {
      width: '500px',
      data: {
        title: 'confirm_to_delete',
        is_show_cancel: true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != null) {
        this.isLoading = true;
        this.apiService
          .smartBackupDataCollectedFromOnline(donatedObject, true)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
          .subscribe(
            (res) => {
              let index = this.data.findIndex(
                (item) => item.item_id === donatedObject.item_id
              );
              this.data.splice(index, 1);
            },
            (error) => {
              console.log(error);
            }
          );
      }
    });
  }

  updateAmount(donatedObject: DonatedObject) {
    //TODO:
    this.apiService
      .smartBackupDataCollectedFromOnline(donatedObject)
      .subscribe((res) => {});
  }

  amountChangeClicked({ itemId, amount }) {
    console.log('On amout nchange', itemId, amount);
    let itemData = this.data.filter((item) => item.item_id === itemId)[0];
    if (!itemData) {
      return;
    }

    if (itemData.amount == 1 && amount == 0) {
      this.showConfirmDeleteDialog(itemData);
      return;
    }
    itemData.amount = amount;
    this.updateAmount(itemData);
  }

  itemClicked(event) {
    // TODO: go to item detail page
  }

  onScroll() {
    console.log('onScroll');
  }

  search(event) {
    console.log(event.target.value);
  }

  pageChange({ pageIndex }) {
    console.log('Page change', pageIndex);
    this.pageIndex = pageIndex;
    this.loadWishlistDetail(this.user.code);
  }

  onOrderBy(event) {
    this.orderBy = event;
    this.loadWishlistDetail(this.user.code);
  }

  onDiyFilter(event) {
    this.onlyShowDiy = event;
    this.loadWishlistDetail(this.user.code);
  }
}
