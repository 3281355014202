import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";

@Component({
  selector: 'app-my-mat-input',
  templateUrl: './my-mat-input.component.html',
  styleUrls: ['./my-mat-input.component.scss']
})
export class MyMatInputComponent implements OnInit{
  @Input() label: string;
  @Input() form_control: FormControl;
  @Input() type: string;
  @Input() errorMsg: string;
  @Input() defaultValue = '';
  @Input() placeholder = '';
  @Input() required: boolean = true;
  @Output() changed = new EventEmitter<string>();

  constructor() {

  }

  changedValue(event) {
    this.changed.emit(event.target.value);
  }

  ngOnInit(): void {
    if (this.form_control == null) {
      this.form_control = new FormControl(this.defaultValue)
    }
  }

}
