import {BaseSimpleModel} from "./BaseSimpleModel";
import {Item} from "./Item";

export class DonatedObject extends BaseSimpleModel {
  id: number;
  uid: number;
  collected_key;
  item_name;
  item_id;
  variation;
  amount;
  isDeleted;
  create_at: Date;
  last_edit: string;
  last_update: Date;
  item: Item;

  constructor(data: DonatedObject = null) {
    super(data);
    if (data.item != null) {
      // console.log('in');
      this.item = new Item(data.item);
    }
    // console.log('outin');
  }

  isDiy(): boolean {
    // console.log('isDiy');
    // console.log(this.variation);
    // console.log(this.collected_key);
    return this.variation == 'diy' || this.collected_key == Item.KEY_OWN_DIY;
  }

}
