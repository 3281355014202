import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from "../../../service/AuthenticationService";
import {APIService} from "../../../service/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {ChatGroup, ChatMessage} from "../../../models/Chat";
import {Room, RoomParticipant} from "../../../models/Room";
import {CommonDialogComponent} from "../../../dialog/common-dialog/common-dialog.component";
import {MatDialog} from "@angular/material/dialog";
// import Timeout = NodeJS.Timeout;
// import Timeout = NodeJS.Timeout;

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {
  chatGroupId: number;
  chat_group: ChatGroup;
  _messages: ChatMessage[];
  unexpectedError = false;
  uid: number;
  waitingList: RoomParticipant[];
  _messageTimer;
  innerHeight: number;
  isSending = false;
  currentText = '';
  isDarkMode = false;
  systemMessageColor = '';
  myBubbleColor = '';
  reversedMessage: ChatMessage[];

  constructor(private service: AuthenticationService, protected apiService: APIService, private route: ActivatedRoute, public _snackBar: MatSnackBar,
              private router: Router, public translate: TranslateService, public dialog: MatDialog) {

    if (!this.service.isSignedIn()) {
      const dialogRef = this.dialog.open(CommonDialogComponent, {
        width: '500px',
        data: {
          title: 'please_sign_in_first',
          is_show_cancel: false,
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        this.router.navigate(['rooms']);
      });
      return;
    }


    this.uid = +service.getUid();
    this.innerHeight = window.innerHeight;

    this.route.params.subscribe(params => {
      this.chatGroupId = params['id'];
      this.apiService.getChatGroup(this.chatGroupId).subscribe(group => {
        console.log('in');
        console.log(group);
        try {
          if (group == null) {
            this.unexpectedError = true;
            console.log('unexpectedError');
            return;
          }
          this.chat_group = new ChatGroup(group);
        } catch (e) {
          console.log(e);
          this.unexpectedError = true;
          return;
        }


        this.apiService.getChatMessages(this.chat_group.id).subscribe(messages => {
            this._messages = [];
            for (let m of messages) {
              this._messages.push(new ChatMessage(m));
            }
            this.reversedMessage = this.reverseArr(this._messages);
            this.fetchWaitingList();
            this.setTimer();
            this.scrollToBottom();
          }
        )
      });
    });
  }

  ngOnInit(): void {
    this.isDarkMode = localStorage.getItem('dark-theme') == 'true';
    this.systemMessageColor = !this.isDarkMode ? '#d4eaf4' : '#303030';
    this.myBubbleColor = !this.isDarkMode ? '#e1ffc7' : '#255344';
    // let asd = [1,2,3,4];
    // console.log(asd);
    // console.log(asd);
  }

  getStyle() {
    return {
      'height': (this.innerHeight - 64 - 200) + 'px',
    };
  }

  scrollToBottom() {
    setTimeout(() => {
      let top = document.getElementById('chat_list');
      if (top !== null) {
        top.scrollTo(0, top.scrollHeight * 2);
        top = null;
      }
    }, 500);
  }

  keypress(event) {

    if (event.key == 'Enter') {
      this.currentText = event.target.value;
      this.sendMessage();
    }
    // this.isSendingData = true;
    // console.log(event);
  }

  sendClick() {
    this.sendMessage();
  }

  valueChanged(event) {
    // console.log(event);
    this.currentText = event.target.value;
  }

  async sendMessage() {
    if (!this.service.isSignedIn()) {
      this._snackBar.open('please_sign_in_first', null, {duration: 3000});
      return;
    }

    if (this.isSending ||
      this.currentText == null ||
      this.currentText.length == 0) {
      return;
    }

    // console.log(this.)

    this.isSending = true;


    let chatMessage: ChatMessage = await this.apiService.sendMessage(this.chat_group.id, this.currentText).toPromise();
    if (chatMessage == null) {
      //fail
      this._snackBar.open('send_fail', null, {duration: 3000});
    } else {
      this.currentText = '';
      let tmp = new ChatMessage(chatMessage);
      this._messages = [tmp].concat(this._messages);
      this.reversedMessage = this.reverseArr(this._messages);
      this.scrollToBottom();
    }
    this.isSending = false;

  }


  async loadMessage() {
    let messages: ChatMessage[] = await this.apiService.getChatMessages(this.chat_group.id, this.getLastOtherMessageTime()).toPromise();
    if (messages != null && messages.length > 0) {
      let newMessages: ChatMessage[] = [];
      for (let message of messages) {
        newMessages.push(new ChatMessage(message));
      }

      this._messages = newMessages.concat(this._messages);
      this.reversedMessage = this.reverseArr(this._messages);
      this.scrollToBottom();
    }
  }

  reverseMessages(): ChatMessage[] {
    return this.reverseArr(this._messages);
  }

  reverseArr(input) {
    var ret = [];
    for (var i = input.length - 1; i >= 0; i--) {
      ret.push(input[i]);
    }
    return ret;
  }


  getLastOtherMessageTime(): string {
    for (let message of this._messages) {
      if (message.isSystemMessage() || !message.isMyMessage(this.uid)) {
        return message.created_at;
      }
    }
    if (this._messages.length == 0 || this._messages[0] == null) {
      return '';
    }
    return this._messages[0].created_at;
  }

  async fetchRoomInfo() {
    if (!this.chat_group.isRoom()) {
      return;
    }

    let room = await this.apiService.fetchRoom(this.chat_group.room.id, this.uid == null ? '' : this.uid.toString()).toPromise();

    this.chat_group.room = new Room(room);
    if (this.chat_group.room.isBlock() || this.chat_group.room.isKicked()) {
      this._snackBar.open(await this.translate.get('you_have_been_kicked').toPromise(), null, {duration: 3000});
      this.router.navigate(['rooms']);
    } else if (this.chat_group.room.isClosed()) {
      this._snackBar.open(await this.translate.get('room_closed').toPromise(), null, {duration: 3000});
      this.router.navigate(['rooms']);
    }
  }

  setTimer() {
    this._messageTimer = setInterval(() => {
      this.loadMessage();
      if (this.chat_group.isRoom() && !this.chat_group.room.isClosed()) {
        this.fetchRoomInfo();
        this.fetchWaitingList();
      }
    }, 5000);
  }

  fetchWaitingList() {
    // if (!this.chat_group.isHost(this.uid)) {
    //   return;
    // }
    this.apiService.getRoomMemberList(this.chat_group.room.id, Room.TYPE_ROOM_WAITING).subscribe(members => {
      if (members != null) {
        this.waitingList = [];
        for (let m of members) {
          this.waitingList.push(new RoomParticipant(m));
        }
      }
    });
  }

  async closeRoom() {
    let room: Room = await this.apiService.closeRoom(this.chat_group.room.id).toPromise();
    if (room == null || typeof room == 'string') {
      this._snackBar.open(await this.translate.get('unexpected_error').toPromise(), null, {duration: 3000});
      return;
    }
    // this._snackBar.open(await this.translate.get('unexpected_error').toPromise(), null, {duration: 3000});
    this.router.navigate(['rooms']);
  }

  async updateSuccess() {
    this._snackBar.open(await this.translate.get('success').toPromise(), null, {duration: 3000});
    this.fetchRoomInfo();
  }

  async leave() {
    let roomParticipant: RoomParticipant = await this.apiService.leaveRoom(this.chat_group.room.id).toPromise();
    if (roomParticipant == null || typeof roomParticipant == 'string') {
      this._snackBar.open(await this.translate.get('unexpected_error').toPromise(), null, {duration: 3000});
      return;
    }
    this.chat_group.room.my_position = roomParticipant;

    this.router.navigate(['rooms']);
  }

  ngOnDestroy(): void {
    clearInterval(this._messageTimer);
  }
}
