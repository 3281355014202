import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {APIService} from "../../service/api.service";
import {ActivatedRoute} from "@angular/router";
import {OverlayContainer} from "@angular/cdk/overlay";

@Component({
  selector: 'app-critterpedia-dialog',
  templateUrl: './critterpedia-dialog.component.html',
  styleUrls: ['./critterpedia-dialog.component.scss']
})
export class CritterpediaDialogComponent implements OnInit{
  aid: number;
  isDark;

  constructor(overlayContainer: OverlayContainer, public dialogRef: MatDialogRef<CritterpediaDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.aid = data['aid'];
    if ((localStorage.getItem('dark-theme') ?? 'false') == 'true') {
      overlayContainer.getContainerElement().classList.add('unicorn-dark-theme');
    }
  }

  ngOnInit(): void {
    // this.dialogRef.
  }

  onClose(): void {
    // set the closeMessage property here
    // this.closeMessage = "Pizza!";
    // this.dialogRef.
    this.dialogRef.close('ref');
  }


  onNoClick(): void {
    this.dialogRef.close();
  }
}
