export abstract class BaseClass {
  protected constructor(data?: BaseClass) {
    if (data != null) {
      const atrArray = Object.getOwnPropertyNames(data);
      for (const atr of atrArray) {
        // if (data[atr] == null) {
        //   this[atr] = null;
        // } else if (data[atr].length == 0) {
        //   this[atr] = '';
        // } else if (!Number.isNaN(Number(data[atr]))) {
        //   this[atr] = Number(data[atr]);
        // } else {
        this[atr] = data[atr];
        // }
      }
    }
  }

  // abstract initData();

  getFilePath(path: string): string {
    // if (path != null && path.length != 0) {
    //   return Constants.SERVER_PATH + path!;
    // } else {
    return '';
    // }
  }


  getProperty(key: string): string {
    return this[key];
  }

  getReadableDate(dateTime) {
    let theDate = new Date(dateTime);
    // console.log(theDate);
    let today = new Date();
    if (theDate.toLocaleString().split(',')[0] === today.toLocaleString().split(',')[0]) {
      // today
      return theDate.getHours() + ':' + theDate.getMinutes();
    }

    today.setDate(today.getDate() + 7);
    if (theDate <= today) {
      // this week
      switch (theDate.getDay()) {
        case 1:
          return 'Mon';
        case 2:
          return 'Tue';
        case 3:
          return 'Wed';
        case 4:
          return 'Thur';
        case 5:
          return 'Fri';
        case 6:
          return 'Sat';
        default:
          return 'Sun';
      }
    }

    return theDate.toLocaleString().split(',')[0];
    // return theDate.setDate();
    // return theDate.getDate()
  }
}
