<div class="main-container">
  <div class="main-body" *ngIf="!isLoading">
    <!--    <div *ngIf="isShowTitle">-->
    <div mat-dialog-title>
      <h1 style="font-weight: bold;display: inline-block">{{animal.getName()}}</h1>
    </div>

    <div mat-dialog-content>
      <img [src]="animal.getWebImage()" class="item-image">
      <div class="title">{{'seasonality'|translate}}</div>
      <div class="row season">
        <div class="col-sm-6" *ngFor="let h of ['n','s']">
          <div class="sub-title">{{h == 'n' ? 'northern' : 'southern'|translate}}</div>
          <table style="width: 100%;text-align: center;">
            <tr *ngFor="let r of [0,1,2]">
              <td *ngFor="let c of [0,1,2,3]">
                <div class="a-month" [ngClass]="getMonthClass(h,r * 4 + c)">
                  {{months[r * 4 + c].monthString}}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div>
        <span class="title">{{'current_active_hours'|translate}}</span>
        <span>{{animal.getTime()|translate}}</span>
      </div>
      <table style="width: 100%;table-layout: fixed; display: none">
        <tr>
          <td colspan="12">AM</td>
          <td colspan="12">PM</td>
        </tr>
        <tr>
          <td colspan="6">12</td>
          <td colspan="6">6</td>
          <td colspan="6">12</td>
          <td colspan="6">6</td>
        </tr>
        <tr>
          <td *ngFor="let h of times" class="time" [ngClass]="getTimeClass(h)"><span></span></td>
        </tr>
      </table>
      <div>
        <span class="title">{{'location'|translate}}</span>
        <span>{{animal.getLocation()|translate}}</span>
      </div>
      <div>
        <span class="title">{{'weather'|translate}}</span>
        <span>{{animal.getWeather()|translate}}</span>
      </div>
      <div>
        <span class="title">{{'price'|translate}}</span>
        <span>{{animal.getPriceString()}}</span>
      </div>
    </div>
    <div mat-dialog-actions style="text-align: right;display: block;">
      <button mat-icon-button color="primary" (click)="openShareLink()">
        <mat-icon>share</mat-icon>
      </button>
      <a mat-icon-button color="primary" routerLink="{{'/critterpedia/'+animal.id}}" *ngIf="!isPage" target="_blank">
        <mat-icon>open_in_new</mat-icon>
      </a>
      <a mat-mini-fab color="primary" (click)="donatedClicked()" class="own-img" *ngIf="animal.isCollected()">
        <img [src]="'assets/img/my_owl.png'">
      </a>
      <a mat-icon-button (click)="donatedClicked()" *ngIf="!animal.isCollected()">
        <img [src]="'assets/img/un_my_owl.png'">
      </a>

      <!--        <button mat-icon-button-->
      <!--                shareButton="facebook" [url]="'https://animalguide.app/'"-->
      <!--                #fbBtn="shareButton">-->
      <!--          <fa-icon [icon]="'fa-facebook-f'" prefix="fab"></fa-icon>-->
      <!--        </button>-->
      <!--        <button mat-icon-button-->
      <!--                shareButton="twitter" [url]="'https://animalguide.app/'"-->
      <!--                #fbBtn="shareButton">-->
      <!--          &lt;!&ndash;          <fa-icon size="lg"></fa-icon>&ndash;&gt;-->
      <!--          <mat-icon>open_in_new</mat-icon>-->
      <!--        </button>-->
      <!--        <button mat-icon-button aria-label="Example icon button with a open in new tab icon" color="primary">-->
      <!--          <img [src]="'assets/img/my_owl.png'">-->
      <!--        </button>-->
    </div>
  </div>
</div>
