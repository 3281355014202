<div class="main-container">
  <div class="main-body">

    <!--    ads-->
    <ng-adsense
      [adClient]="'ca-pub-3459399242895554'"
      [adSlot]="1562127059"
      [display]="'block'"
      [adFormat]="'auto'"
      [className]="'adsbygoogle'"
      [fullWidthResponsive]="true"
    ></ng-adsense>

<!--    <div *ngIf="isShowTitle">-->
      <h1>{{('room')|translate}}</h1>
      <mat-divider></mat-divider>
<!--    </div>-->
    <button mat-button style="margin-left: auto;display: block;" (click)="createRoom()" *ngIf="myHostingRoom==null">
      <mat-icon>add</mat-icon>
    </button>
    <button mat-button style="margin-left: auto;display: block;" (click)="openMyRoom()" *ngIf="myHostingRoom!=null">
      <mat-icon>portrait</mat-icon>
    </button>

    <common-item-list [data]="data" [isLoading]="isLoading"
                      [filterPair]="[langFilter,roomTypeFilter,roomTagFilter,joinedFilter]"
                      (itemClicked)="itemClicked($event)"
                      (filter2Clicked)="filterChanged($event)"
                      [breakpoint]="3"
                      [type]="type" [pageIndex]="pageIndex" [pageSize]="pageSize" [length]="length"
                      (pageIndexChange)="pageChanged($event)" [viewType]="'room'"></common-item-list>
  </div>
</div>
