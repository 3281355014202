<mat-card
  style="margin-bottom: 8px; width: 100%"
  class="wishlist-item"
  ngClass="wishlist-item {{ itemType }}"
>
  <div class="wishlist-tilte" (click)="navigateToDetail()">
    <mat-icon class="whishlist-ico" *ngIf="data?.id == -1">done_all</mat-icon>
    <mat-icon class="whishlist-ico" *ngIf="data?.id == -2">start</mat-icon>
    {{ data.title | translate }}
  </div>
  <button
    mat-icon-button
    [matMenuTriggerFor]="menu"
    *ngIf="data?.id !== -1 && data?.id !== -2 && userId === data.uid"
  >
    <mat-icon class="whishlist-ico">more_vert</mat-icon>
  </button>
</mat-card>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="onEdit.emit()">
    <mat-icon class="whishlist-menu-ico">edit</mat-icon>
    {{ "edit_item" | translate }}
  </button>
  <button mat-menu-item (click)="onDelete.emit()">
    <mat-icon class="whishlist-menu-ico">delete</mat-icon>
    {{ "delete" | translate }}
  </button>
</mat-menu>
