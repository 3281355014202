<!--<div style="display: table;-->
<!--    margin: 12px auto;">-->
<!--  <mat-button-toggle-group name="fontStyle" aria-label="Font Style">-->
<!--    <mat-button-toggle [checked]="type==tag.value"-->
<!--                       (change)="filterChanged(tag.value)"-->
<!--                       *ngFor="let tag of filterList">{{tag.name|translate}}{{ tag.amount }}</mat-button-toggle>-->
<!--  </mat-button-toggle-group>-->
<!--</div>-->
<!--<loading-component *ngIf="isLoading"></loading-component>-->
<!--<mat-grid-list [cols]="breakpoint" rowHeight="1:1" *ngIf="!isLoading" (window:resize)="onResize($event)">-->
<!--  <mat-grid-tile *ngFor="let c of animals">-->
<!--    <app-item-card-container style="width: 100%;height: 100%;" [name]="c.getName()"-->
<!--                             [image]="c.icon_image" [donated]="c.donatedObject"-->
<!--                             (click)="itemClicked(c)"></app-item-card-container>-->
<!--  </mat-grid-tile>-->
<!--</mat-grid-list>-->
<div class="main-container">
  <div class="main-body">
    <div *ngIf="isShowTitle">
    <h1>{{'critterpedia'|translate}}</h1>
    <mat-divider></mat-divider>
    </div>
    <common-item-list [data]="animals" [isLoading]="isLoading"
                      [filterList]="filterList" (itemClicked)="itemClicked($event)"
                      (filterClicked)="filterChanged($event)"
                      [type]="type"></common-item-list>
  </div>
</div>
