export const environment = {
  url: 'https://api.dev.animalguide.app/',
  webUrl: 'https://dev.animalguide.app/',
  production: true,
  firebase: {
    apiKey: "AIzaSyD6n08en20dx-wz-CiisvtBB-zwHTqiy8k",
    authDomain: "animal-guide-app.firebaseapp.com",
    databaseURL: "https://animal-guide-app.firebaseio.com",
    projectId: "animal-guide-app",
    storageBucket: "animal-guide-app.appspot.com",
    messagingSenderId: "774549037584",
    appId: "1:774549037584:web:c392a6cad2e2c60f4e2095",
    measurementId: "G-0TY68E39R0"
  }
};
