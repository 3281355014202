<footer>
  <mat-divider></mat-divider>
  <mat-toolbar style="height: auto">
    <div>
      <div style="margin-bottom: 14px;max-width: 100%">
        <span class="download-title">{{'download'|translate}}</span>
        <a class="footer-download" href="https://apps.apple.com/us/app/animal-guide/id1507549116?ls=1"
           target="_blank">
          <img class="desktop-img" [src]="isDark?'assets/img/app_store_white.png':'assets/img/app_store.png'">
          <!--          <img class="mobile-img" [src]="'assets/img/app_store_white.png'">-->
        </a>
        <a class="footer-download"
           href="https://play.google.com/store/apps/details?id=com.playgroup.animalcrossguide"
           target="_blank">
          <img class="desktop-img" [src]="isDark?'assets/img/play_store_white.png':'assets/img/play_store.png'">
        </a>
      </div>
      <h6 style="text-align: center;white-space:normal;margin: 10px 0 ;font-size: 10px;">*DISCLAIMER* This is an
        unofficial app for
        Animal
        crossing: new horizons. This app
        is not affiliated with the Animal crossing brand, Niantic, Animal crossing:new horizons, or Nintendo."
      </h6>
      <div style="margin:0 auto;padding-bottom: 12px; max-width: 100%;white-space:normal;text-align: center">
        <a mat-button routerLink="/privacy_policy">{{'privacy_policy'|translate}}</a>
        <a mat-button routerLink="/tos">{{'tos'|translate}}</a>
        <a style="text-align: center;margin-left: auto;margin-right: auto;">
          Copyright © 2020 {{ 'animal_guide' | translate }} | Powered by PlayGroup</a>
      </div>

    </div>
  </mat-toolbar>
</footer>

