export class NameValuePair {
  name: string;
  value: string;
  amount: string;

  constructor(name: string, value: string, amount?: number) {
    this.name = name;
    this.value = value;
    this.amount = isNaN(amount) ? '' : " (" + amount.toString() + ")";
  }
}
