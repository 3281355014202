import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-common-tag',
  templateUrl: './common-tag.component.html',
  styleUrls: ['./common-tag.component.scss']
})
export class CommonTagComponent implements OnInit {
  @Input() text: string;
  @Input() color;
  @Input() bgColor;
  @Input() opacity: number;

  constructor() {
  }

  ngOnInit(): void {
  }

  getStyle() {
    return {
      'color': this.color ?? '',
    };
  }

}
