import {Component, Input, OnInit} from '@angular/core';
import {DonatedObject} from "../../models/DonatedObject";
import {Animal} from "../../models/Animal";
import {NameValuePair} from "../../models/NameValuePair";
import {AuthenticationService} from "../../service/AuthenticationService";
import {APIService} from "../../service/api.service";
import {Fossil} from "../../models/Fossil";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-fossil',
  templateUrl: './fossil.component.html',
  styleUrls: ['./fossil.component.scss']
})
export class FossilComponent implements OnInit {

  @Input() collectedList: DonatedObject[] = [];
  @Input() uid: string;
  @Input() isShowTitle: boolean = true;
  isLoading = true;
  type = '';
  data: Fossil[] = [];
  filterList: NameValuePair[] = [];

  constructor(service: AuthenticationService, protected apiService: APIService, private router: Router,
              private route: ActivatedRoute, public dialog: MatDialog) {
    if (this.uid == null) {
      this.uid = service.getUid();
    }
  }

  getFilterList(): NameValuePair[] {
    return [];
  }

  ngOnInit(): void {
    this.filterList = this.getFilterList();
    this.loadItems();
  }

  itemClicked(data) {
    console.log(data);
  }

  filterChanged(type) {
    this.type = type;
    this.loadItems();
  }

  handleCustomCollected(collectedList: DonatedObject[], item_id: string, key?: string): DonatedObject {
    for (let d of collectedList) {
      if (key != null) {
        if (d.collected_key == key && item_id == d.item_id) {
          return d;
        }
      } else {
        if (item_id == d.item_id) {
          return d;
        }
      }
    }
    return null;
  }

  protected loadItems() {
    this.isLoading = true;
    this.apiService.getFossils(this.uid, this.isShowTitle ? '' : Fossil.KEY).subscribe(animals => {
      this.data = [];
      for (let a of animals) {
        let animal = new Fossil(a);
        animal.donatedObject = this.handleCustomCollected(this.collectedList, animal.id.toString(), 'fossil');
        this.data.push(animal);
      }
    }, null, () => {
      this.isLoading = false;
    });
  }
}
