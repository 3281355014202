import {Profile} from "./User";
import {Room} from "./Room";
import {BaseClass} from "./BaseClass";
import {DateTimeHelper} from "../utils/DateTimeHelper";

export class ChatGroup extends BaseClass {
  id: number;
  creator_id: number;
  participate_id: number;
  type: string;
  isBlocked: boolean;
  is_creator_mute: boolean;
  is_participate_mute: boolean;
  last_message_at: string;
  last_message: ChatMessage;
  last_read: ChatGroupRead;
  creator: Profile;
  participate: Profile;
  room: Room;
  static CHAT_GROUP_TYPE_individual = 'individual';
  static CHAT_GROUP_TYPE_ROOM = 'room';

  constructor(data: ChatGroup) {
    super(data);
    if (data.last_message != null) {
      this.last_message = new ChatMessage(data.last_message);
    }
    if (data.last_read != null) {
      this.last_read = new ChatGroupRead(data.last_read);
    }
    if (data.creator != null) {
      this.creator = new Profile(data.creator);
    }
    if (data.participate != null) {
      this.participate = new Profile(data.participate);
    }
    if (data.room != null) {
      this.room = new Room(data.room);
    }
  }


  isMuted(uid: number): boolean {
    if (this.isRoom()) {
      if (this.room.isHost(uid)) {
        return this.room.is_host_muted;
      } else {
        return this.room.my_position.is_muted;
      }
    } else {
      if (this.creator_id == uid) {
        return this.is_creator_mute;
      } else {
        return this.is_participate_mute;
      }
    }
  }

  isCreator(uid: number): boolean {
    return this.creator_id == uid;
  }

  isHost(uid: number): boolean {
    return this.isRoom() && this.room != null && this.room.isHost(uid);
  }

  getLastMessage(): string {
    if (this.last_message != null) {
      return this.last_message.text;
    }
    return '';
  }

  isRoom(): boolean {
    return this.type == ChatGroup.CHAT_GROUP_TYPE_ROOM && this.room != null;
  }

  getIcon(uid: number): string {
    if (this.type == ChatGroup.CHAT_GROUP_TYPE_individual) {
//      debugPrint("type:$type");
      return uid == this.creator_id ? this.participate.photoUrl : this.creator.photoUrl;
    }
    return '';
  }

  isRead(uid: number): boolean {
    if (this.last_message == null || this.last_message.isMyMessage(uid)) {
      return true;
    }

    if (this.last_read == null) {
      return false;
    }

    try {
      return new Date(this.last_read.last_read_time).getMilliseconds()
        - new Date(this.last_message_at).getMilliseconds() >
        0;
    } catch (e) {
      return true;
    }
  }

  getGroupTitle(uid: number): string {
//    debugPrint('type:$type');
//    debugPrint('room:${room != null}');
    if (this.type == ChatGroup.CHAT_GROUP_TYPE_individual) {
      return uid == this.creator_id ? this.participate.name : this.creator.name;
    } else if (this.type == ChatGroup.CHAT_GROUP_TYPE_ROOM && this.room != null) {
//      debugPrint('return room.name');
      return this.room.name;
    }
    return '';
  }

  getTime(): string {
    return DateTimeHelper.conventServerTimeToLocalTimeString(this.last_message_at);
  }


}

export class ChatMessage extends BaseClass {
  id: number;
  sender_id: number;
  chat_group_id: number;
  type: string;
  content_type: string;
  target: string;
  target_id: string;
  text: string;
  created_at: string;
  sender: Profile;

  constructor(data: ChatMessage) {
    super(data);
    if (data.sender != null) {
      this.sender = new Profile(data.sender);
    }
  }


  getTime(): string {
    return DateTimeHelper.conventServerTimeToLocalTimeString(this.created_at);
  }

  getSenderName(): string {
    if (this.sender == null) {
      return '';
    }
    return this.sender.name;
  }

  isMyMessage(uid: number): boolean {
    return this.sender_id == uid;
  }

  isSystemMessage(): boolean {
    return this.type == 'system';
  }
}

export class ChatGroupRead extends BaseClass {
  id: number;
  chat_group_id: number;
  uid: number;
  last_read_time: string;


  constructor(data: BaseClass) {
    super(data);
  }
}
