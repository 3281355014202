import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DonatedObject} from "../../models/DonatedObject";
import {Fossil} from "../../models/Fossil";
import {NameValuePair} from "../../models/NameValuePair";
import {AuthenticationService} from "../../service/AuthenticationService";
import {APIService} from "../../service/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {Room} from "../../models/Room";
import {FilterPair} from "../../models/FilterPair";
import {RoomDialogComponent} from "./room-dialog/room-dialog.component";
import {environment} from "../../../environments/environment";
import {ManageRoomInfoComponent} from "../../dialog/manage-room-info/manage-room-info.component";

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss']
})
export class RoomComponent implements OnInit {

  myHostingRoom: Room;
  isLoading = true;
  selectedLang: string = '';
  type: string = '';
  tag: string = '';
  data: Room[] = [];
  keyword = '';
  joined = false;
  uid: string;
  langFilter;
  joinedFilter = new FilterPair('participated_rooms', [
    new NameValuePair('all', ''),
    new NameValuePair('yes', 'true'),
  ]);
  roomTypeFilter = new FilterPair('type', [
    new NameValuePair('all', ''),
    new NameValuePair('free', Room.TYPE_FREE),
    new NameValuePair('entrance_fee_required', Room.TYPE_TIPS_REQUIRED),
  ]);
  roomTagFilter = new FilterPair('room_tags', [
    new NameValuePair('all', ''),
    new NameValuePair('turnip', 'turnip'),
    new NameValuePair('meteor_shower', 'meteor_shower'),
    new NameValuePair('touch', 'touch'),
    new NameValuePair('special_character', 'special_character'),
    new NameValuePair('free_gift', 'free_gift'),
    new NameValuePair('event', 'event'),
  ]);
  filterPairs: FilterPair[] = [];

  isShowTitle = false;
  pageIndex = 0;
  length = 0;
  pageSize = 100;

  constructor(public service: AuthenticationService, protected apiService: APIService, private router: Router,
              private route: ActivatedRoute, public dialog: MatDialog) {
    // let category = route.snapshot.data['type'];
    // if (category != null) {
    //   this.category = category;
    //   this.isShowTitle = true;
    // }
    // // console.log(route.snapshot.data['title']);
    // if (this.uid == null) {
    //   this.uid = service.getUid();
    // }
    this.loadMyHostingRoom();
  }

  createRoom() {
    const dialogRef = this.dialog.open(ManageRoomInfoComponent, {
      width: '500px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.openChatRoom(result.chat_group_id);
        this.loadItems();
        this.loadMyHostingRoom();
      }
    });
  }

  openMyRoom() {
    if (this.myHostingRoom == null) {
      return;
    }
    this.openChatRoom(this.myHostingRoom.chat_group_id);
  }

  async loadMyHostingRoom() {
    try {
      this.myHostingRoom = await this.apiService.getMyHostingRoom().toPromise();
      if (this.myHostingRoom == null || typeof this.myHostingRoom == 'string') {
        this.myHostingRoom = null;
      } else {
        this.myHostingRoom = new Room(this.myHostingRoom);
      }
    } catch (e) {
      console.log(e);
      this.myHostingRoom = null;
    }


  }

  ngOnInit(): void {
    let langCode = localStorage.getItem('langCode');
    let langIndex = 0;
    switch (langCode) {
      case 'de':
        langIndex = 1;
        break;
      case 'en':
        langIndex = 2;
        break;
      case 'es':
        langIndex = 3;
        break;
      case 'fr':
        langIndex = 4;
        break;
      case 'it':
        langIndex = 5;
        break;
      case 'ja':
        langIndex = 6;
        break;
      case 'ko':
        langIndex = 7;
        break;
      case 'ru':
        langIndex = 8;
        break;
      case 'zh':
        langIndex = 9;
        break;
      case 'zh-CN':
        langIndex = 10;
        break;
    }
    this.langFilter = new FilterPair('languages', [
      new NameValuePair('all', ''),
      new NameValuePair('Deutsch', 'de'),
      new NameValuePair('English', 'en'),
      new NameValuePair('español', 'es'),
      new NameValuePair('français', 'fr'),
      new NameValuePair('Italiano', 'it'),
      new NameValuePair('日本語', 'ja'),
      new NameValuePair('한국어', 'ko'),
      new NameValuePair('русский язык', 'ru'),
      new NameValuePair('繁體中文', 'zh_TW'),
      new NameValuePair('简体中文', 'zh_CN'),
    ], langIndex);
    this.filterPairs = [this.langFilter, this.roomTypeFilter, this.roomTagFilter, this.joinedFilter];
    this.uid = this.service.getUid();
    this.loadItems();
  }

  itemClicked(room: Room) {

    if (this.uid == room.host_id.toString() || room.isJoined()) {
      this.openChatRoom(room.chat_group_id);
      return;
    }

    // console.log(room);
    const dialogRef = this.dialog.open(RoomDialogComponent, {
      width: '500px',
      data: room
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed'+result);
      // reload
      // this.loadItems();
      // this.animal = result;
      if (result != null) {
        //open chatroom
        this.openChatRoom(room.chat_group_id)
      }
    });
  }

  openChatRoom(chat_group_id: number) {
    window.open(environment.webUrl + "chat/" + chat_group_id);
  }

  filterChanged(event) {
    this.filterPairs[event.index].selected = event.value;
    this.loadItems();
  }

  pageChanged(newValue) {
    this.pageIndex = newValue;
    this.loadItems();
  }


  protected loadItems() {
    this.apiService.getRooms(this.langFilter.selected.value, this.roomTypeFilter.selected.value, this.keyword, this.roomTagFilter.selected.value, this.pageIndex + 1, this.pageSize, this.joinedFilter.selected.value).subscribe(serializerItem => {
      this.data = [];
      for (let a of serializerItem.items) {
        let room = new Room(a);
        this.data.push(room);
      }
      this.length = serializerItem._meta.totalCount;
    }, null, () => {
      this.isLoading = false;
    });
  }


}
