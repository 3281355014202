import {Component, OnInit} from '@angular/core';
import {Profile} from "../../models/User";
import {SignInDialogComponent} from "../../dialog/sign-in-dialog/sign-in-dialog.component";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {APIService} from "../../service/api.service";
import {AngularFireAnalytics} from "@angular/fire/analytics";
import {AuthenticationService} from "../../service/AuthenticationService";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  title = 'animal-guide-web';
  supportedLangValues = ['de', 'en', 'es', 'fr', 'it', 'ja', 'ko', 'ru', 'zh', 'zh-CN'];
  supportedLangTitles = ['Deutsch', 'English', 'español', 'français', 'Italiano', '日本語', '한국어', 'русский язык', '繁體中文', '简体中文'];
  defaultLanguage = 'en';
  isDark = false;
  // isSignIn = false;
  screenshots = ["1_home.png",
    "2_checklist.png",
    "3_single_animal.png",
    "4_turnips.png",
    "5_diy.png",
    "6_furniture.png",
    "7_villager.png",
    "8_profile.png",];

  constructor(public translate: TranslateService, public dialog: MatDialog, public apiService: APIService,
              private  analytics: AngularFireAnalytics, public service: AuthenticationService, private route: ActivatedRoute,
              private router: Router) {
    this.route.params.subscribe(params => {

      let uid = params['uCode'];
      console.log(uid);
      if (uid == null || uid.length == 0) {
        return;
      }

    });
  }

  ngOnInit(): void {

  }


  afterSignIn(result: Profile) {
    console.log('The dialog was closed');
    console.log(result);
    if (result != null) {
      this.service.signIn(result);
      this.router.navigate(['profile']);
    }
  }

  openSignInDialog() {
    let dialogRef = this.dialog.open(SignInDialogComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe((result: Profile) => {
      this.afterSignIn(result);
    });
  }

  openSignUpDialog() {
    let dialogRef = this.dialog.open(SignInDialogComponent, {
      width: '400px',
      data: {isSignUp: true}
    });
    dialogRef.afterClosed().subscribe((result: Profile) => {
      this.afterSignIn(result);
    });
  }

}
