<h1 mat-dialog-title *ngIf="isSignUp">{{'sign_up'|translate}}</h1>
<h1 mat-dialog-title *ngIf="!forgotPasswordMode&&!isSignUp">{{'sign_in'|translate}}</h1>
<h1 mat-dialog-title *ngIf="forgotPasswordMode&&!isSignUp">{{'forgot_password'|translate}}</h1>
<div mat-dialog-content class="sign-in-container">
  <div>
    <app-my-mat-input type="email"
                      errorMsg="{{'enter_valid_email'|translate}}"
                      [form_control]="email"
                      label="{{'email'|translate}}"></app-my-mat-input>
  </div>
  <div>
    <app-my-mat-input label="{{'password'|translate}}"
                      type="password"
                      errorMsg="{{'password_at_least'|translate}}"
                      [form_control]="password"
                      *ngIf="!forgotPasswordMode"></app-my-mat-input>
  </div>
  <div *ngIf="!isLoading && !isSignUp">
    <div *ngIf="!forgotPasswordMode">
      <button mat-button (click)="forgetPasswordClick()"
              style="width: auto;margin-left: auto;display: block;margin-bottom: 12px;">{{'forgot_password'|translate}}?
      </button>
      <button mat-flat-button color="primary" (click)="signInToFirebase()">{{'sign_in'|translate}}</button>
    </div>
    <div *ngIf="forgotPasswordMode">
      <button mat-flat-button color="primary" (click)="resetPassword()"
              style="margin-bottom: 12px">{{'confirm'|translate}}</button>
      <button mat-flat-button (click)="cancelResetPassword()">{{'cancel'|translate}}</button>
    </div>
  </div>
  <div *ngIf="isSignUp">
    <div>
      <mat-checkbox
        [checked]="agreeTerms"
        (change)="agreeTerms = $event.checked">
        {{'i_agree'|translate}}
        <span><a href="/tos" target="_blank">{{'tos'|translate}}</a>
        & <a href="/privacy_policy" target="_blank">{{'privacy_policy'|translate}}</a></span>
      </mat-checkbox>
    </div>
    <button mat-flat-button color="primary" (click)="signUpToFirebase()"
            style="margin: 12px 0" *ngIf="!isLoading">{{'confirm'|translate}}</button>
  </div>
  <mat-spinner diameter="40" style="margin: 0 auto" *ngIf="isLoading"></mat-spinner>
</div>
