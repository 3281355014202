import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {OverlayContainer} from "@angular/cdk/overlay";
import {MatDialog} from "@angular/material/dialog";
import {SignInDialogComponent} from "./dialog/sign-in-dialog/sign-in-dialog.component";
import {APIService} from "./service/api.service";
import {AngularFireAnalytics} from "@angular/fire/analytics";
import {environment} from "../environments/environment";
import {AuthenticationService} from "./service/AuthenticationService";
import {Profile} from "./models/User";
import {ActivatedRoute, Router} from "@angular/router";
import {FirebaseApp} from "@angular/fire";
import {Meta, Title} from "@angular/platform-browser";
import {DeviceDetectorService} from "ngx-device-detector";
import {DeeplinkService} from "./service/deeplink-service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  // title = 'animal-guide-web';
  supportedLangValues = ['de', 'en', 'es', 'fr', 'it', 'ja', 'ko', 'ru', 'zh', 'zh-CN'];
  supportedLangTitles = ['Deutsch', 'English', 'español', 'français', 'Italiano', '日本語', '한국어', 'русский язык', '繁體中文', '简体中文'];
  // menu = [];
  defaultLanguage = 'en';
  isDark = false;
  isDesktop = false;
  navMenu: NavItem[] = [
    // new NavItem('market', 'market'),
    new NavItem('rooms', 'room'),
    new NavItem('critterpedia', 'critterpedia'),
    // new NavItem('furniture', 'furniture'),
    // new NavItem('apparel', 'apparel'),
    // new NavItem('work_of_art', 'work_of_art'),
    // new NavItem('song_list', 'songList'),
    // new NavItem('fossil', 'fossil'),
    // new NavItem('villager', 'villager'),
    // new NavItem('profile', 'profile'),
  ];

  constructor(public translate: TranslateService, public dialog: MatDialog, public apiService: APIService,
              private  analytics: AngularFireAnalytics, public service: AuthenticationService,
              private router: Router, private titleService: Title,
              private metaTagService: Meta, private deviceService: DeviceDetectorService, private meta: Meta,
              private deeplinkService: DeeplinkService) {

    this.titleService.setTitle("Animal Guide");
    this.isDesktop = this.deviceService.isDesktop();

    this.prodSetting();
    this.meta.updateTag({property: 'og:title', content: 'okok'});
    this.isDark = localStorage.getItem('dark-theme') == 'true';
    // a.setUserProperties
    translate.addLangs(this.supportedLangValues);
    translate.setDefaultLang(this.defaultLanguage);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    const browserLang = translate.getBrowserLang();
    const browserCultureLang = translate.getBrowserCultureLang();
    const savedLang = localStorage.getItem('langCode');
    if (savedLang != null) {
      translate.use(localStorage.getItem('langCode'));
    } else if (this.supportedLangValues.includes(browserCultureLang)) {
      translate.use(browserCultureLang);
    } else if (this.supportedLangValues.includes(browserLang)) {
      translate.use(browserLang);
    } else {
      translate.use('en');
    }
    this.translate.get('animal_guide').subscribe(string => {
      this.titleService.setTitle(string);
    })
  }

  ngOnInit(): void {
    this.apiService.checkCredentials();
    this.meta.updateTag({property: 'og:title', content: 'okok1'});
  }

  prodSetting() {
    if (environment.production) {
      window.console.log = function () {
      };   // disable any console.log debugging statements in production mode
    }
  }

  changeTheme() {
    this.isDark = !this.isDark;
    localStorage.setItem('dark-theme', String(this.isDark));
  }

  changeLang(lang: string) {
    // this.translate.use(lang);
    localStorage.setItem('langCode', lang);
    window.location.reload();
  }

  afterSignIn(result: Profile) {
    console.log('The dialog was closed');
    console.log(result);
    if (result != null) {
      this.service.signIn(result);
      this.router.navigate(['profile']);
    }
  }

  signOut() {
    this.service.signOut();
  }

  openSignInDialog() {
    let dialogRef = this.dialog.open(SignInDialogComponent, {
      width: '400px',
      data: {isSignUp: false}
    });

    dialogRef.afterClosed().subscribe((result: Profile) => {
      this.afterSignIn(result);
    });
  }

  openInApp() {
    // console.log("agacnh://animalguide.app"+this.router.url);
    this.deeplinkService.deeplink(this.router.url);
    //   let ua = navigator.userAgent.toLowerCase();
    //   let isAndroid = ua.indexOf("android") > -1; // android check
    //   let isIphone = ua.indexOf("iphone") > -1; // ios check
    //   if (isIphone == true) {
    //     let app = {
    //       launchApp: function () {
    //         setTimeout(function () {
    //           window.location.href = "https://apps.apple.com/us/app/animal-guide/id1507549116?ls=1";
    //         }, 25);
    //         window.location.href = "animalcrossguide://catalog-scanner"; //which page to open(now from mobile, check its authorization)
    //       },
    //       openWebApp: function () {
    //         window.location.href = "https://apps.apple.com/us/app/animal-guide/id1507549116?ls=1";
    //       }
    //     };
    //     app.launchApp();
    //   } else if (isAndroid == true) {
    //     let app = {
    //       launchApp: function () {
    //         window.location.replace("bundlename://catalog-scanner"); //which page to open(now from mobile, check its authorization)
    //         setTimeout(this.openWebApp, 500);
    //       },
    //       openWebApp: function () {
    //         window.location.href = "https://play.google.com/store/apps/details?id=com.playgroup.animalcrossguide";
    //       }
    //     };
    //     app.launchApp();
    //   } else {
    //     //navigate to website url
    //   }
  }

  openSignUpDialog() {
    let dialogRef = this.dialog.open(SignInDialogComponent, {
      width: '400px',
      data: {isSignUp: true}
    });
    dialogRef.afterClosed().subscribe((result: Profile) => {
      this.afterSignIn(result);
    });
  }


}


class NavItem {
  path: string;
  title: string;


  constructor(path: string, title: string) {
    this.path = path;
    this.title = title;
  }
}
