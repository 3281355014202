import {Profile, UserLike} from "./User";
import {BaseClass} from "./BaseClass";
import {DateTimeHelper} from "../utils/DateTimeHelper";

export class Room extends BaseClass {
  static TYPE_FREE = 'free';
  static TYPE_TIPS_REQUIRED = 'tips_required';
  static TYPE_ROOM_WAITING = 'waiting';
  static TYPE_ROOM_INVITED = 'invited';
  static TYPE_ROOM_FINISH = 'finish';
  static TYPE_ROOM_LEFT = 'left';
  static TYPE_ROOM_KICKED = 'kick';
  static TYPE_ROOM_BLOCK = 'block';

  id: number;
  code: string;
  host_id: number;
  langCode: string;
  type: string = 'free';
  is_host_muted: boolean;
  turnip_price: number;
  special_character: string;
  name: string;
  c_name: string;
  chat_group_id: number;
  island_name: string;
  sw_code: string;
  island_passcode: string;
  timezone: number;
  how_to_leave: string = 'airport';
  entry_condition: string;
  photo: string;
  notes: string;
  meta_data: string;
  password: string;
  ticket: number;
  bell: number;
  userLike: UserLike;
  is_private: boolean;
  current_position: number;
  people_waiting: number;
  max_participant: number;
  auto_send_invite_interval: number;
  tip_requested: boolean;
  always_show_passcode: boolean;
  temp_stop: boolean;
  is_full: boolean;
  tags: RoomTag[] = [];
  host: Profile;
  room_start_at: string;
  will_close_at: string;
  closed_at: string;
  my_position: RoomParticipant;


  constructor(data: Room = null) {
    super(data);
    if (data != null) {


      if (data.host != null) {
        this.host = new Profile(data.host);
      }
      if (data.my_position != null) {
        this.my_position = new RoomParticipant(data.my_position);
      }

      if (data.userLike != null) {
        this.userLike = new UserLike(data.userLike);
      }
      if (data.tags != null) {
        this.tags = [];
        for (let tag of data.tags) {
          this.tags.push(new RoomTag(tag));
        }
      }
    }
  }

  isLiked(): boolean {
    return this.userLike != null;
  }

  isClosed(): boolean {
    return this.closed_at != null;
  }

  isJoined(): boolean {
    return this.my_position != null &&
      (this.my_position.current_status == Room.TYPE_ROOM_WAITING);
  }

  isTipsRequired(): boolean {
    return this.type == Room.TYPE_TIPS_REQUIRED;
  }

  isHost(uid: number): boolean {
    if (uid == null) {
      return false;
    }
    return this.host_id == uid;
  }

  isWaiting(): boolean {
    return this.my_position != null &&
      this.my_position.current_status == Room.TYPE_ROOM_WAITING;
  }

  isInvited(): boolean {
    return this.my_position != null &&
      this.my_position.current_status == Room.TYPE_ROOM_INVITED;
  }

  isKicked(): boolean {
    return this.my_position != null &&
      this.my_position.current_status == Room.TYPE_ROOM_KICKED;
  }

  isBlock(): boolean {
    return this.my_position != null && this.my_position.current_status == Room.TYPE_ROOM_BLOCK;
  }


  getCreatedTime() {
    return DateTimeHelper.conventServerTimeToLocalTimeString(this.room_start_at);
    // let serverDate = new Date(this.room_start_at);
    // serverDate.setHours(serverDate.getHours() - 8 - (new Date().getTimezoneOffset() / 60));
    // return serverDate.toLocaleString();
  }

}

export class RoomParticipant extends BaseClass {
  id: number;
  room_id: number;
  uid: number;
  meta_data: string;
  position: number;
  liked_host: boolean;
  is_muted: boolean;
  current_status: string;
  user: Profile;

  constructor(data: RoomParticipant) {
    super(data);
    if (data.user != null) {
      this.user = new Profile(data.user);
    }
  }

  getMyStatusColor(): string {
    switch (this.current_status) {
      case Room.TYPE_ROOM_WAITING:
        return "#4CAF50";
      case Room.TYPE_ROOM_INVITED:
        return "#2196F3";
      case Room.TYPE_ROOM_KICKED:
        return "#F44336";
      default:
        return null;
    }
  }
}

export class RoomTag extends BaseClass {
  id: number;
  room_id: number;
  tag: string;

  constructor(data: RoomTag, tag = null) {
    super(data);
    if (tag != null) {
      this.tag
        = tag;
    }
  }

}
