import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Animal, MyMonth, MyTime} from "../../models/Animal";
import {APIService} from "../../service/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {DonatedObject} from "../../models/DonatedObject";
import {AuthenticationService} from "../../service/AuthenticationService";

@Component({
  selector: 'app-critterpedia-detail',
  templateUrl: './critterpedia-detail.component.html',
  styleUrls: ['./critterpedia-detail.component.scss']
})
export class CritterpediaDetailComponent implements OnInit {
  @Input() aid: number;
  isLoading = true;
  isPage = false;
  animal: Animal;
  months: MyMonth[] = [];
  times: MyTime[] = [];
  langCode = 'en';
  current = new Date();

  constructor(private service: AuthenticationService, protected apiService: APIService, private route: ActivatedRoute, private _snackBar: MatSnackBar,
              private router: Router, public translate: TranslateService) {
    this.handleParams();
    this.langCode = localStorage.getItem('langCode') ?? 'en';
    console.log(this.langCode);
  }

  ngOnInit(): void {

    this.loadItems();
  }

  handleParams() {
    this.route.params.subscribe(params => {
      this.aid = params['id'];
      if (this.aid != null) {
        this.isPage = true;
      }
    });
  }

  openShareLink() {
    this.translate.get('copied').subscribe((res: string) => {
      this.openSnackBar(res);
    });
  }

  openSnackBar(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }

  donatedClicked() {

    if (!this.service.isSignedIn()) {
      this.translate.get('please_sign_in_first').subscribe((res: string) => {
        this.openSnackBar(res);
      });
    }

    let d = new DonatedObject();
    console.log(this.animal.type);
    if (this.animal.isCollected()) {
      // remove
      d = this.animal.donatedObject;
      d.isDeleted = true;
    } else {
      // add
      // let d = new DonatedObject();
      d.collected_key = this.animal.type;
      d.item_id = this.animal.gameId.toString();
      d.amount = 1;
    }
    this.apiService.updateCollected(d).subscribe(result => {
      if (result != null && result.id >= 0) {
        console.log('ok');
        if (result.isDeleted) {
          this.animal.donatedObject = null;
        } else {
          this.animal.donatedObject = result;
        }
      }
    });
  }

  // openNewWindow() {
  //   this.router.navigate(['profile']);
  // }

  protected loadItems() {
    this.isLoading = true;
    this.apiService.getAnimal(this.aid).subscribe(a => {
      this.animal = new Animal(a);
      this.months = this.animal.getMonthList(this.langCode);
      this.times = this.animal.getTimeList();
    }, null, () => {
      this.isLoading = false;
    });
  }

  getMonthClass(her, month) {
    let classNames = '';
    if (this.current.getMonth() == month) {
      classNames += ' this-month ';
    }
    if ((her == 'n' && this.months[month].isNorthActive) || (her == 's' && this.months[month].isSouthActive)) {
      classNames += ' available-month ';
    }
    // this.animal.months[0].
    return classNames;
  }

  getTimeClass(time: MyTime) {
    let classNames = '';
    if (this.current.getHours() == time.time) {
      classNames += ' this-time ';
    }
    if (time.isActive) {
      classNames += ' available-time ';
    }
    return classNames;
  }

}
