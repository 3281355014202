import {DonatedObject} from "./DonatedObject";
import {BaseClass} from "./BaseClass";
import {BaseSimpleModel} from "./BaseSimpleModel";

export class Animal extends BaseSimpleModel {
  id: number;
  gameId: number;
  type: string;
  name: string;
  icon_image: string;
  img: string;
  furniture_image: string;
  weather: string;
  total_catches_to_unlock: number;
  shadow_size: string;
  sell_price: number;
  location: string;
  icon_filename: string;
  critterpedia_filename: string;
  months: AnimalMonth[];
  times: AnimalTime[];
  donatedObject: DonatedObject;
  isLastHour: boolean = false;
  t_name: string;

  constructor(data: Animal) {
    super(data);
  }

  getDiveFishImage() {
    return `assets/img/dive_fish/dive_${this.gameId}.png`;
  }

  isCollected(){
    return this.donatedObject!=null;
  }

  getTimeList(): MyTime[] {
    let myTimes: MyTime[] = [];
    for (let i = 0; i < 24; i++) {
      myTimes.push(new MyTime(i));
    }

    for (let m of this.times) {
      let adjustedEnd = m.time_end;
      if (m.time_start > m.time_end) {
        adjustedEnd += 24;
      }
      if (m.time_start == 0 && m.time_end == 23) {
        adjustedEnd = 24;
      }
      for (var i = m.time_start; i < adjustedEnd; i++) {
        myTimes[i > 23 ? i - 24 : i].isActive = true;
      }
    }

    return myTimes;
  }

  getMonthList(langCode: string): MyMonth[] {
    let myMonths = [
      new MyMonth(1, langCode),
      new MyMonth(2, langCode),
      new MyMonth(3, langCode),
      new MyMonth(4, langCode),
      new MyMonth(5, langCode),
      new MyMonth(6, langCode),
      new MyMonth(7, langCode),
      new MyMonth(8, langCode),
      new MyMonth(9, langCode),
      new MyMonth(10, langCode),
      new MyMonth(11, langCode),
      new MyMonth(12, langCode),
    ];
    console.log(this.months);
    for (let m of this.months) {
      let adjustedEnd = m.month_end;
      if (m.month_start > m.month_end) {
        adjustedEnd += 12;
      }
      if (m.m_hemisphere == 'n') {
        console.log('in');
        for (let i = m.month_start; i <= adjustedEnd; i++) {
          myMonths[i > 12 ? i - 13 : i - 1].isNorthActive = true;
        }
      } else {
        for (let i = m.month_start; i <= adjustedEnd; i++) {
          myMonths[i > 12 ? i - 13 : i - 1].isSouthActive = true;
        }
      }
    }
    console.log(myMonths);
    return myMonths;
  }


  getTime() {
    let result = '';
    for (let t of this.times) {
      if (t.time_start == 0 && t.time_end == 23) {
        return 'allDay';
      }
      if (result.length != 0) {
        result += ', ';
      }

      if (t.time_start < 12) {
        result += `${t.time_start} AM`;
      } else {
        result += `${t.time_start - 12} PM`;
      }

      if (t.time_end < 12) {
        result += `- ${t.time_end} AM`;
      } else {
        result += `- ${t.time_end - 12} PM`;
      }
    }
    return result;
  }


  getLocation(): string {
    if (this.location == null) {
      return '';
    }
    switch (this.location.toLowerCase()) {
      case "river":
        return 'River';
      case "pond":
        return 'Pond';
      case "river (clifftop)":
        return 'RiverClifftop';
      case "river (mouth)":
        return 'RiverMouth';
      case "sea":
        return 'Sea';
      case "pier":
        return 'Pier';
      case "sea (rainy days)":
        return 'SeaRainyDays';
      case "flying":
        return 'Flying';
      case "flying near blue/purple/black flowers":
        return 'Flying_near_color_flower';
      case "flying near flowers":
        return 'Flying_near_flowers';
      case "flying near trash (boots, tires, cans) or rotten turnips":
        return 'Flying_near_trash';
      case "flying near water":
        return 'Flying_near_water';
      case "on white flowers":
        return 'On_white_flowers';
      case "flying by light":
      case "flying near light sources":
        return 'FlyingByLight';
      case "on trees":
      case "on trees (any kind)":
        return 'OnTrees';
      case "on ground":
      case "on the ground":
        return 'OnGround';
      case "on flowers":
        return 'OnFlowers';
      case "shaking trees":
        return 'FallsFromShakingTrees';
      case "shaking trees (hardwood or cedar only)":
        return 'Shaking_trees_hardwood_cedar';
      case "underground":
      case "underground (dig where noise is loudest)":
        return 'Underground';
      case "ponds":
        return 'Ponds';
      case "on hardwood/cedar trees":
        return 'On_hardwood_cedar_trees';
      case "ponds and rivers":
      case "on rivers/ponds":
        return 'PondsAndRivers';
      case "on tree stumps":
        return 'OnTreeStumps';
      case "on palm trees":
        return 'OnPalmTrees';
      case "pushing snowballs":
        return 'PushingSnowballs';
      case "near trees, disguised as furniture leaf":
      case "disguised under trees":
        return 'NearTrees';
      case "on rotten turnips":
        return 'OnRottenFood';
      case "beach":
        return 'Beach';
      case "on rocks/bushes":
        return 'on_rocks_bushes';
      case "on rocks at beach":
      case "on beach rocks":
        return 'OnRocksAtBeach';
      case "on trash items":
        return 'OnTrashItems';
      case "on villagers":
        return 'OnVillagers';
      case "on rocks (raining)":
        return 'OnRocksRaining';
      case "hit rocks":
      case "from hitting rocks":
        return 'HitRocks';
      case "disguised on shoreline":
        return 'disguised_on_shoreline';
    }
    return this.location;
  }

  getWeather(): string {
    if (this.weather == null) {
      return '';
    }
    switch (this.weather) {
      case 'Any except rain':
        return 'Any_except_rain';
      case 'Rain only':
        return 'Rain_only';
      default:
        return 'Any_weather';
    }
  }

  getPriceString(): string {
    return "$" + this.sell_price;
  }

  getWebImage() {
    // if (this.type == 'dive_fish') {
    //   return this.getDiveFishImage();
    // }
    return this.icon_image;
  }

  getName() {
    if (this.t_name == null || this.t_name.length == 0) {
      return this.titleCaseWord(this.name);
    }

    return this.titleCaseWord(this.t_name);
  }
}

export class AnimalMonth extends BaseSimpleModel {
  id: number;
  aid: number;
  m_hemisphere: string;
  month_start: number;
  month_end: number;


  constructor(data: AnimalMonth) {
    super(data);
  }
}

export class AnimalTime extends BaseSimpleModel {
  id: number;
  aid: number;
  time_start: number;
  time_end: number;

  constructor(data: AnimalTime) {
    super(data);
  }
}

export class MyMonth {
  month: number;
  monthString = '';
  isSouthActive = false;
  isNorthActive = false;


  constructor(month: number, langCode) {
    this.month = month;
    const formatter = new Intl.DateTimeFormat(langCode, {month: 'short'});
    this.monthString = formatter.format(new Date(2000, month - 1, 2))
  }
}

export class MyTime {
  time: number;
  isActive = false;


  constructor(time: number) {
    this.time = time;
  }
}

