<div class="main-container">
  <div class="main-body">
    <div *ngIf="isShowTitle">
      <h1>{{(category == 'furniture' ? 'furniture' : 'apparel')|translate}}</h1>
      <mat-divider></mat-divider>
    </div>

    <common-item-list [data]="data" [isLoading]="isLoading"
                      [filterList]="filterList" (itemClicked)="itemClicked($event)"
                      (filterClicked)="filterChanged($event)"
                      [type]="type" [pageIndex]="pageIndex" [pageSize]="pageSize" [length]="length"
                      (pageIndexChange)="pageChanged($event)"></common-item-list>

  </div>
</div>
