import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DonatedObject } from 'src/app/models/DonatedObject';

@Component({
  selector: 'app-wish-item',
  templateUrl: './wish-item.component.html',
  styleUrls: ['./wish-item.component.scss'],
})
export class WishItemComponent implements OnInit {
  @Input() data: DonatedObject;
  @Output() onAmountChange = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}
}
