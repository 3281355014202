<div style="display: table;
    margin: 12px auto;">


  <div *ngFor="let filter of filterPair;let i = index" style="margin: 8px 0">
    <span
      style="margin-right:2%;text-align: right;width: 10%;display: inline-block;">{{filter.title|translate}}</span>
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style"
                             class="nav-menu-desktop" style="max-width: 85%"
                             [className]="filter.pairs.length <= 1 ?'alwaysShow nav-menu-desktop':'nav-menu-desktop'">
      <mat-button-toggle [checked]="filter.selected.value==tag.value"
                         (change)="filter2Changed(i,tag)"
                         *ngFor="let tag of filter.pairs">{{tag.name|translate}}{{ tag.amount }}</mat-button-toggle>
    </mat-button-toggle-group>
    <mat-form-field class="nav-menu-mobile"
                    [className]="filter.pairs.length <= 1 ?'alwaysDisappear nav-menu-mobile':'nav-menu-mobile'">
      <mat-select [value]="filter.selected.value">
        <mat-option *ngFor="let tag of filter.pairs" [value]="tag.value" (change)="filter2Changed(i,tag)">
          {{tag.name|translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>


  <div *ngIf="filterList.length!=0">
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style"
                             class="nav-menu-desktop"
                             [className]="this.filterList.length <= 3 ?'alwaysShow nav-menu-desktop':'nav-menu-desktop'">
      <mat-button-toggle [checked]="type==tag.value"
                         (change)="filterChanged(tag.value)"
                         *ngFor="let tag of filterList">{{tag.name|translate}}{{ tag.amount }}</mat-button-toggle>
    </mat-button-toggle-group>
    <mat-form-field class="nav-menu-mobile"
                    [className]="this.filterList.length <= 3 ?'alwaysDisappear nav-menu-mobile':'nav-menu-mobile'">
      <mat-select [value]="type">
        <mat-option *ngFor="let tag of filterList" [value]="tag.value" (change)="filterChanged(tag.value)">
          {{tag.name|translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<loading-component *ngIf="isLoading"></loading-component>
<mat-grid-list [cols]="breakpoint" *ngIf="!isLoading && listType=='grid'" rowHeight="1:1"
               (window:resize)="onResize($event)">
  <mat-grid-tile *ngFor="let c of data">
    <app-item-card-container style="width: 100%;height: 100%;" [name]="c.getName()"
                             [image]="c.getWebImage()" [donated]="c.donatedObject"
                             (click)="itemClick(c)" *ngIf="viewType=='common'"></app-item-card-container>
    <app-room-item-view style="width: 100%;height: 100%;" *ngIf="viewType=='room'" [room]="c"
                        (click)="itemClick(c)"></app-room-item-view>
  </mat-grid-tile>
</mat-grid-list>
<mat-list *ngIf="!isLoading&& listType=='list'" style="max-width: 400px;margin-left: auto;margin-right: auto;"
          >
  <!-- <app-wishlist-item [donatedObject]="c" style="width: 100%;" *ngFor="let c of data"></app-wishlist-item> -->
</mat-list>

<mat-paginator [length]="length" [hidePageSize]="true" [pageSize]="pageSize" [pageIndex]="pageIndex"
               *ngIf="pageSize!=null" (page)="pageChange($event)" style="margin-top: 12px;">
</mat-paginator>
