<div class="main-container">
  <div class="main-body">
    <div *ngIf="isShowTitle">
      <h1>{{'songList'|translate}}</h1>
      <mat-divider></mat-divider>
    </div>
    <common-item-list [data]="data" [isLoading]="isLoading"
                      [filterList]="filterList" (itemClicked)="itemClicked($event)"
                      (filterClicked)="filterChanged($event)"
                      [type]="type"></common-item-list>
  </div>
</div>
