import { Component, Inject, OnInit } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-wishlist-edit-dialog',
  templateUrl: './wishlist-edit-dialog.component.html',
  styleUrls: ['./wishlist-edit-dialog.component.scss'],
})
export class WishlistEditDialogComponent implements OnInit {
  wishlistName: string = '';
  editForm: FormControl;

  constructor(
    overlayContainer: OverlayContainer,
    public dialogRef: MatDialogRef<WishlistEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.wishlistName = data['wishlistName'];
  }

  ngOnInit(): void {
    this.editForm = new FormControl(
      this.wishlistName ? this.wishlistName : '',
      [Validators.required]
    );
  }

  onClose(): void {
    this.dialogRef.close();
  }

  confirmClicked(): void {
    if (this.editForm.valid) {
      this.dialogRef.close({ isOk: true, title: this.editForm.value });
    }
  }

  onChange($event) {}
}
