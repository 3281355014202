import {Component, OnInit} from '@angular/core';
import {FormControl, FormsModule} from "@angular/forms";

@Component({
  selector: 'app-dart-to-ts',
  templateUrl: './dart-to-ts.component.html',
  styleUrls: ['./dart-to-ts.component.scss']
})
export class DartToTsComponent {
  tmp: FormControl = new FormControl();
  result = '';

  constructor() {
  }

  run() {
    // console.log(this.tmp.value);
    this.result = '';
    let theValues = this.tmp.value.replace(/(?:\r\n|\r|\n)/g, '').replace('  ', '');
    console.log(theValues.split(';\n').length)
    for (let r of theValues.split(';')) {
      console.log(r);
      if (r == null) {
        return;
      }
      let defaultValueArray = r.split(' = ');
      let defaultValue = '';
      if (defaultValueArray.length > 1) {
        defaultValue = '=' + defaultValueArray[defaultValueArray.length - 1];
        r = r.substr(0, r.indexOf(' = '));
      }
      let tArray = r.split(' ');
      let dataType = this.dataTypeConvert(tArray[tArray.length - 2]);
      let dataName = tArray[tArray.length - 1];
      if (dataType == null) {
        return;
      }
      if (defaultValue.length > 0) {
        this.result += `${dataName}: ${dataType} ${defaultValue};\n`;
      } else {
        this.result += `${dataName}: ${dataType};\n`;
      }
    }
  }

  dataTypeConvert(type: string) {
    if (type == null) {
      return null;
    }
    if (type == 'dynamic>>') {
      return 'Array<[string,any]>';
    }

    if (type.indexOf('List') >= 0) {

      let dataType = type.split('List<')[1].split('>')[0];
      // if (dataType.indexOf('map') >= 0) {
      //   // dataType = 'Array[string,any]';
      //   return 'Array[string,any]';
      // }
      return `${dataType}[]`;
    } else if (type.indexOf('Map') >= 0) {
      return 'Map';
    } else {
      switch (type) {
        case 'int':
          return 'number';
        case 'String':
          return 'string';
        case 'bool':
          return 'boolean'
        case 'DateTime':
          return 'Date'
        default:
          return type;
      }
    }
  }


}
