import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-dart-to-typescript-tool',
  templateUrl: './dart-to-typescript-tool.component.html',
  styleUrls: ['./dart-to-typescript-tool.component.scss']
})
export class DartToTypescriptToolComponent implements OnInit {

  translatedCode = '';

  constructor() {
  }

  ngOnInit(): void {
    // console.log("event");
  }

  changedText(event) {
    console.log(event);
    const value = event.target.value;
    const variableList = value.split('\n');
    console.log(variableList);
    var result = '';
    for (const row of variableList) {
      if (result.length == 0) {
        result += this.dartToTypescript(row);
      } else {
        result += '\n' + this.dartToTypescript(row);
      }

    }
    this.translatedCode = result;
  }

  dartToTypescript(text) {
    // text.replace(';','');
    var tmpList = text.replace('  ', '').replace(';', '').split(' ');
    console.log(tmpList);
    var result = '';
    var index = 0;
    if (tmpList[0] == 'final') {
      index = 1;
    }
    return tmpList[index + 1] + ":" + this.dataTypeConvert(tmpList[index]) + ';';
  }

  dataTypeConvert(type) {
    switch (type) {
      case 'int':
      case 'double':
        return 'number';
      case 'bool':
        return 'boolean';
      case 'String':
      case 'DateTime':
        return 'string';
      default:
        return type;
    }
  }
}
