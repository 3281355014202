import {Component, Input, OnInit} from '@angular/core';
import {Profile} from "../../models/User";

@Component({
  selector: 'app-user-profile-row',
  templateUrl: './user-profile-row.component.html',
  styleUrls: ['./user-profile-row.component.scss']
})
export class UserProfileRowComponent implements OnInit {
  @Input() profile: Profile;
  @Input() iconSize = 36;


  constructor() {
  }

  ngOnInit(): void {
  }

  getUrlPhoto() {
    return this.profile.photoUrl ?? 'assets/img/Untitled.png';
  }

}
