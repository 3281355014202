<mat-card style="width: 100%;
    height: 100%; margin:8px;" class="row align-items-center ripple-container" matRipple>
  <!--  <div>-->
  <div class="item-tags">
    <div>
      <collected-icon matIcon="star" [amount]="diy.amount.toString()" *ngIf="diy!=null"></collected-icon>
      <collected-icon matIcon="local_library" [amount]="buildList.amount.toString()"
                      *ngIf="buildList!=null"></collected-icon>
    </div>
    <div style="right: 0;top: 0;position: absolute;">
      <collected-icon matIcon="star" [amount]="donated.amount.toString()" *ngIf="donated!=null"></collected-icon>
      <collected-icon matIcon="star" [amount]="favorite.amount.toString()" *ngIf="favorite!=null"></collected-icon>
      <collected-icon matIcon="done_all" [amount]="owned.amount.toString()" *ngIf="owned!=null"></collected-icon>
    </div>
  </div>
  <img [src]="image" class="item-image" [style]="'filter: grayscale('+gray+'%);'">
  <!--  </div>-->
  <div class="item-name">{{name}}</div>
</mat-card>
