import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { WishList } from 'src/app/models/WishList';
import { WISHLIST_TYPE } from 'src/app/app.constant';
import { AuthenticationService } from 'src/app/service/AuthenticationService';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-wishlist-item',
  templateUrl: './wishlist-item.component.html',
  styleUrls: ['./wishlist-item.component.scss'],
})
export class WishlistItemComponent implements OnInit {
  @Input() data: WishList;
  @Output() onDelete = new EventEmitter<void>();
  @Output() onEdit = new EventEmitter<void>();

  userId: number;
  itemType = WISHLIST_TYPE.NORMAL;

  constructor(
    public authService: AuthenticationService,
    public router: Router,
    public activeRoute: ActivatedRoute
  ) {
    this.userId = parseInt(authService.getUid());
  }

  ngOnInit(): void {
    console.log('Data', this.data);
    this.setItemType();
  }

  setItemType() {
    switch (this.data.id) {
      case -2:
        this.itemType = WISHLIST_TYPE.OWNED;
        break;
      case -1:
        this.itemType = WISHLIST_TYPE.FAVOURITE;
        break;
      default:
        this.itemType = WISHLIST_TYPE.NORMAL;
        break;
    }
  }

  navigateToDetail() {
    console.log('navigate');
    const code = this.data.id >= 0 ? this.data.code : `u${this.authService.userCode}`;
    const params = this.data.id >= 0 ? {} : { key: this.itemType };
    this.router.navigate([code], {
      relativeTo: this.activeRoute,
      queryParams: params
    });
  }
}
