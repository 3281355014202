import {Component, Inject, OnInit} from '@angular/core';
import {OverlayContainer} from "@angular/cdk/overlay";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {RoomParticipant} from "../../models/Room";
import {APIService} from "../../service/api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-block-dialog',
  templateUrl: './block-dialog.component.html',
  styleUrls: ['./block-dialog.component.scss']
})
export class BlockDialogComponent implements OnInit {
  room_id: number;
  roomName: string;
  roomParticipant: RoomParticipant;
  reason: string = '';
  isSending: boolean = false;

  constructor(overlayContainer: OverlayContainer, public dialogRef: MatDialogRef<BlockDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              protected apiService: APIService, public _snackBar: MatSnackBar, public translate: TranslateService,) {
    this.room_id = data['room_id'];
    this.roomName = data['roomName'];
    this.roomParticipant = data['roomParticipant'];
    if ((localStorage.getItem('dark-theme') ?? 'false') == 'true') {
      overlayContainer.getContainerElement().classList.add('unicorn-dark-theme');
    }
  }

  ngOnInit(): void {
    // this.dialogRef.
  }

  onClose(): void {
    this.dialogRef.close();
  }

  test(event) {
    console.log(event);
  }

  async confirmClicked() {
    if (this.isSending) {
      return;
    }
    this.isSending = true;
    let result: boolean = await this.apiService.reportRoom(this.room_id, this.reason, '',
      this.roomParticipant == null ? null : this.roomParticipant.uid).toPromise();
    this.isSending = false;
    if (result == null || !result) {
      this._snackBar.open(await this.translate.get('unexpected_error').toPromise());
      return;
    }
    this._snackBar.open(await this.translate.get('thankYorForYourReport').toPromise());
    this.dialogRef.close(true);
  }
}
