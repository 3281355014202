import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'collected-icon',
  templateUrl: './collected-icon.component.html',
  styleUrls: ['./collected-icon.component.scss']
})
export class CollectedIconComponent implements OnInit {

  @Input() image: string;
  @Input() matIcon: string;
  @Input() amount: string = '';

  constructor() {
  }

  ngOnInit(): void {
    this.amount = this.amount == '1' ? '' : this.amount;
  }

}
