import {BaseSimpleModel} from "./BaseSimpleModel";
import {KKCD} from "./KKCD";
import {Item} from "./Item";
import {DonatedObject} from "./DonatedObject";

export class Villager extends BaseSimpleModel {
  static KEY: string = 'islander';
  static KEY_FAVOURITE: string = 'islander_favourite';
  static KEY_DONATE: string = 'islander_dondate';

  sex: string;
  bday: string;
  hobby: string;
  personality: string;
  catchphrase: string;
  favorite_song_id: string;
  favorite_song: KKCD;
  wallpaper: Item;
  flooring: Item;
  target: string;
  house_image: string;
  motto: string;
  style_1: string;
  style_2: string;
  color_1: string;
  color_2: string;
  unique_entry_id: string;
  specie: string;
  animalIslanderTranslate: AnimalIslanderTranslate;
  favourite: DonatedObject;

  constructor(data: Villager, donatedObject?: DonatedObject) {
    super(data);
    this.donatedObject = donatedObject;
  }
}

export class AnimalIslanderTranslate {
  id: number;
  animal_id: number;
  langCode: string;
  name: string;
  signature_phrase: string;
  target: string;
  motto: string;

}
