<div class="main-container">
  <div class="main-body">
    <loading-component *ngIf="isLoading"></loading-component>
    <div *ngIf="!isLoading && profile!=null">
      <mat-card>
        <div class="row align-items-center">
          <div class="col-sm-4"><img
            [src]="(profile.photoUrl==null || profile.photoUrl.length==0)?'assets/img/Untitled.png':profile.photoUrl"
            class="user-pic"></div>
          <!--          <div class="col-sm-4"><img [src]="'assets/img/Untitled.png'" class="user-pic"></div>-->
          <div class="col-sm-8" style="padding: 0 20px;">
            <!--                        <div layout="row" layout-align="center center">-->
            <div class="user-container user-content">{{profile.sw_code}}</div>
            <mat-divider class="user-divider"></mat-divider>
            <div class="user-container user-content">{{profile.des}}</div>
            <mat-divider class="user-divider"></mat-divider>
            <div class="user-container row align-items-center">
              <img class="user-img-icon" src="assets/img/icon_island.png"><span
              class="user-content" style="margin-right: 12px;">{{profile.island_name}}</span>
              <img class="user-img-icon" src="assets/img/icon_tree.png"><span
              class="user-content">{{profile.specialty|translate}}</span>
            </div>
            <mat-divider class="user-divider"></mat-divider>
            <div class="user-container user-content">{{profile.name}}</div>
            <mat-divider class="user-divider"></mat-divider>
            <div class="row align-items-center user-container">
              <mat-icon class="user-icon ">language</mat-icon>
              <span class="user-content">{{profile.getLanguage()}}</span>
            </div>
            <mat-divider class="user-divider"></mat-divider>
            <div class="row align-items-center user-container">
              <mat-icon class="user-icon">access_time</mat-icon>
              <span class="user-content">{{profile.getTimezone()}}</span>
            </div>
          </div>
        </div>
      </mat-card>
      <mat-divider style="margin-top: 20px"></mat-divider>
      <mat-tab-group (selectedTabChange)="test($event)">
        <mat-tab label="{{'critterpedia'|translate}}{{' ('+collectedAnimal.length+')'}}">
          <ng-template matTabContent>
            <app-critterpedia [uid]="userId" [isShowTitle]="false"></app-critterpedia>
          </ng-template>

        </mat-tab>
        <mat-tab label="{{'furniture'|translate}}">
          <ng-template matTabContent>
            <app-furniture [collectedList]="collectedFurniture" [uid]="userId"
                           [collected_key]="'KEY_OWN_ITEM'"></app-furniture>
          </ng-template>
        </mat-tab>
        <mat-tab label="{{'apparel'|translate}}">
          <app-furniture [collectedList]="collectedFurniture" [uid]="userId" [category]="'apparel'"
                         [collected_key]="'KEY_OWN_ITEM'"></app-furniture>
        </mat-tab>
        <mat-tab label="{{'villager'|translate}}">
          <app-villager [collectedList]="collectedVillager" [uid]="userId" [isShowTitle]="false"></app-villager>
        </mat-tab>
        <!--        <mat-tab label="{{'works_of_art'|translate}}"> Content 1</mat-tab>-->
        <mat-tab label="{{'songList'|translate}}{{' ('+collectedSong.length+')'}}">
          <app-song [collectedList]="collectedSong" [uid]="userId" [isShowTitle]="false"></app-song>
        </mat-tab>
        <mat-tab label="{{'fossil'|translate}}{{' ('+collectedFossil.length+')'}}">
          <app-fossil [collectedList]="collectedFossil" [uid]="userId" [isShowTitle]="false"></app-fossil>
        </mat-tab>

        <!--        <mat-tab label="Second"> Content 2</mat-tab>-->
        <!--        <mat-tab label="Third"> Content 3</mat-tab>-->
      </mat-tab-group>
    </div>
  </div>
</div>
