import {BaseSimpleModel} from "./BaseSimpleModel";
import {BaseClass} from "./BaseClass";
import {DonatedObject} from "./DonatedObject";

export class Fossil extends BaseSimpleModel {
  static KEY: string = 'fossil';

  constructor(data: Fossil, donatedObject?: DonatedObject) {
    super(data);
    this.donatedObject = donatedObject;
  }
}
