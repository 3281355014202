<app-loading-overlay *ngIf="isLoading"></app-loading-overlay>
<div class="main-container">
  <div class="main-body">
    <div class="page-title">
      <h1>{{ "wishlist" | translate }}</h1>
      <button mat-raised-button class="action-btn" (click)="showEditDialog(-1)">
        +
      </button>
    </div>
    <mat-divider></mat-divider>
    <div class="custom-grid-list row">
      <app-wishlist-item
        class="col-sm-4 col-lg-3"
        *ngFor="let c of data; let i = index"
        [data]="c"
        (onDelete)="onDeleteItem(i)"
        (onEdit)="showEditDialog(i)"
      ></app-wishlist-item>
    </div>
  </div>
</div>
