import {DonatedObject} from "./DonatedObject";
import {BaseSimpleModel} from "./BaseSimpleModel";

export class Item extends BaseSimpleModel {

  static KEY_FAOUR: string = 'my-item';
  static KEY_OWN_DIY: string = 'item-KEY_OWN';
  static KEY_BUILD_LIST: string = 'item-KEY_BUILD_LIST';
  static KEY_OWN_ITEM: string = 'KEY_OWN_ITEM';
  static ITEM_LIMIT: number = 90;

  parent_unique_id: string;
  source: string;
  recipe_source: string;
  buy_price: number;
  size: string;
  category: string;
  filename: string;
  diy: boolean;
  style: string;
  t_name: string;
  t_source: string;
  t_recipe_source: string;
  hha_series: string;
  // bgColor: Color =Colors.transparent;
  itemTranslate: ItemTranslate;
  from: ItemMaterial[];
  variations: Item[];
  variation: string;
  color_1: string;
  color_2: string;
  ownDIY: DonatedObject;
  ownItem: DonatedObject;
  buildList: DonatedObject;


  constructor(data: Item, donatedObject?: DonatedObject, ownDIY?: DonatedObject,
              ownItem?: DonatedObject,
              buildList?: DonatedObject) {
    super(data);
    this.donatedObject = donatedObject;
    this.ownDIY = ownDIY;
    this.ownItem = ownItem;
    this.buildList = buildList;
    this.name = data.name.charAt(0).toUpperCase() + data.name.slice(1);
  }
}

export class ItemTranslate {
  id: number;
  t_name: string;
  t_source: string;
  t_source_notes: string;
  t_recipe_source: string;
  t_recipe_source_note: string;

}

export class ItemMaterial {
  item: Item;
  amount: number;

}
