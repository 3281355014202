import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {DonatedObject} from "../../models/DonatedObject";
import {BaseSimpleModel} from "../../models/BaseSimpleModel";
import {NameValuePair} from "../../models/NameValuePair";
import {AuthenticationService} from "../../service/AuthenticationService";
import {APIService} from "../../service/api.service";
import {FilterPair} from "../../models/FilterPair";

@Component({
  selector: 'common-item-list',
  templateUrl: './common-item-list.component.html',
  styleUrls: ['./common-item-list.component.scss']
})
export class CommonItemListComponent implements OnInit {


  @Input() filterList: NameValuePair[] = [];
  @Input() filterPair: FilterPair[] = [];
  @Input() data: any[] = [];
  @Input() isLoading = false;
  @Input() listType = 'grid';
  @Input() type = '';
  @Input() pageSize;
  @Input() pageIndex;
  @Input() length;
  @Input() viewType = 'common';
  @Output() itemClicked = new EventEmitter<BaseSimpleModel>();
  @Output() filterClicked = new EventEmitter<void>();
  @Output() filter2Clicked = new EventEmitter();
  @Output() pageIndexChange = new EventEmitter<number>();
  @Output() onScrollEvent = new EventEmitter<number>();


  filterAdjustClass = '';
  @Input() breakpoint = 5;

  constructor(public service: AuthenticationService, private  apiService: APIService) {

  }


  ngOnInit(): void {
    // this._loadItems();
    this.breakpoint = (window.innerWidth <= 400) ? 2 : this.breakpoint;
    this.filterAdjustClass = this.filterList.length <= 3 ? 'display:inline-flex!important;' : 'color:red;';
  }

  pageChange(event) {
    this.pageIndexChange.emit(event['pageIndex']);
    // console.log(event['pageIndex']);
  }

  onScroll(){
    this.onScrollEvent.emit();
  }

  itemClick(animal) {
    this.itemClicked.emit(animal);
  }

  filterChanged(type) {
    this.filterClicked.emit(type);
  }

  filter2Changed(index: number, type) {
    this.filter2Clicked.emit({index: index, value: type});
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 400) ? 2 : 5;
  }

}
