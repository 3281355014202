<div>
  <section class="top s1" id="top">
    <div class="row">
      <div class="col-md-6" style="align-self: center;">
        <div style="font-size:large">{{'enjoy_your_animal_crossing_life_on'|translate}}</div>
        <div style="line-height: 28px;font-size: x-large;font-weight: bold">{{'explore_the'|translate}}</div>
        <div style="font-size: xx-large;font-weight: bold;line-height: 32px;">{{'animal_guide'|translate}}</div>
        <!--        <mat-divider style="width: 0;height: 20px"></mat-divider>-->
        <div style="margin: 40px 0;font-size:larger">{{'app_short_des'|translate}}</div>
        <div class="row">
          <a class="col-sm download-img" href="https://apps.apple.com/us/app/animal-guide/id1507549116?ls=1"
             target="_blank">
            <img [src]="'assets/img/app_store.png'">
          </a>
          <a class="col-sm download-img"
             href="https://play.google.com/store/apps/details?id=com.playgroup.animalcrossguide"
             target="_blank">
            <img [src]="'assets/img/play_store.png'">
          </a>
        </div>
      </div>
      <div class="col-md-6">
        <img style="max-width: 100%;margin: 20px auto;"
             [src]="'assets/img/smartmockups_k9y4axgx.png'">
      </div>
    </div>

  </section>
  <section class="feature s2" id="feature">
    <div>
      <div class="section-title">{{'features'|translate}}</div>
      <div class="row">
        <div class="col-md-6">
          <img style="max-width: 100%" [src]="'assets/img/smartmockups_k9tsu80v-1024x803.png'">
        </div>
        <div class="col-md-6" style="align-self: center;font-size: larger">
          <ul>
            <li>{{'real_time_animal'|translate}}</li>
            <li>{{'fish_list'|translate}}</li>
            <li>{{'Insect_list'|translate}}</li>
            <li>{{'diy_recipes'|translate}}</li>
            <li>{{'turnip_price_prediction'|translate}}</li>
            <li>{{'mark_collected_'|translate}}</li>
            <li>{{'work_of_art'|translate}}</li>
            <li>{{'fossil_list'|translate}}</li>
            <li>{{'kk_album'|translate}}</li>
            <li>{{'villager_list'|translate}}</li>
            <li>{{'make_friend'|translate}}</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section class="screenshot s1" id="screenshot">
    <div>
      <div class="section-title">{{'screenshots'|translate}}</div>
      <mat-grid-list cols="4" rowHeight="1242:2208">
        <mat-grid-tile *ngFor="let screenshot of screenshots"><img [src]="'assets/img/screenshots/zh/'+screenshot"
                                                                   style="width: 100%;height: 100%;padding: 5%">
        </mat-grid-tile>
        <!--        <mat-grid-tile>2</mat-grid-tile>-->
        <!--        <mat-grid-tile>3</mat-grid-tile>-->
        <!--        <mat-grid-tile>4</mat-grid-tile>-->
      </mat-grid-list>
    </div>
  </section>
  <!--  </mat-drawer-container>-->
</div>
