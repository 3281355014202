<div class="main-container">
  <div class="main-body">
    <loading-component *ngIf="isLoading"></loading-component>
    <mat-list role="list">
      <div *ngFor="let d of data; ">
        <mat-list-item role="listitem">
          <img src="{{d.image}}" style="height: 100%">
          <img src="/assets/img/diy_icon.png" style="height: 20px" *ngIf="type=='recipes'">
          <div style="margin-left: 20px;">{{d.title}}</div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </div>

    </mat-list>
  </div>
</div>
