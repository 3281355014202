import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DartToTsComponent} from "./tools/dart-to-ts/dart-to-ts.component";
import {ProfileComponent} from "./pages/profile/profile.component";
import {TosComponent} from "./pages/tos/tos.component";
import {PpComponent} from "./pages/pp/pp.component";
import {HomeComponent} from "./pages/home/home.component";
import {RoomComponent} from "./pages/room/room.component";
import {MarketComponent} from "./pages/market/market.component";
import {CritterpediaComponent} from "./pages/critterpedia/critterpedia.component";
import {CritterpediaDetailComponent} from "./pages/critterpedia-detail/critterpedia-detail.component";
import {FurnitureComponent} from "./pages/furniture/furniture.component";
import {FossilComponent} from "./pages/fossil/fossil.component";
import {Villager} from "./models/Villager";
import {VillagerComponent} from "./pages/villager/villager.component";
import {SongComponent} from "./pages/song/song.component";
import {DartToTypescriptToolComponent} from "./pages/dart-to-typescript-tool/dart-to-typescript-tool.component";
import {ChatComponent} from "./pages/chat/chat/chat.component";
import {WishlistComponent} from "./pages/wishlist/wishlist.component";
import {WishlistDetailComponent} from "./pages/wishlist/wishlist-detail/wishlist-detail.component";
import {CatalogScannerComponent} from "./pages/catalog-scanner/catalog-scanner.component";


export const routes: Routes = [
  {path: 'dartToTs', component: DartToTsComponent},
  {path: 'tos', component: TosComponent},
  {path: 'privacy_policy', component: PpComponent},
  {path: 'rooms', component: RoomComponent},
  {path: 'market', component: MarketComponent},
  {path: 'critterpedia', component: CritterpediaComponent},
  {path: 'critterpedia/:id', component: CritterpediaDetailComponent},
  {path: 'furniture', component: FurnitureComponent, data: {type: 'furniture'}},
  {path: 'apparel', component: FurnitureComponent, data: {type: 'apparel'}},
  {path: 'fossil', component: FossilComponent},
  {path: 'villager', component: VillagerComponent},
  {path: 'chat/:id', component: ChatComponent},
  {path: 'song_list', component: SongComponent},
  {path: 'tool', component: DartToTypescriptToolComponent},
  {path: 'wishlist', component: WishlistComponent},
  {path: 'wishlist/:wid', component: WishlistDetailComponent},
  {path: ':uCode/wishlist', component: WishlistComponent},
  {path: ':uCode', component: ProfileComponent},
  {path: 'catalog-scanner/:code', component: CatalogScannerComponent},
  {path: 'profile', component: ProfileComponent}, // old
  {path: 'profile/:id', component: ProfileComponent}, // old
  {path: 'profile/:id/:section', component: ProfileComponent}, // old
  {path: '', component: HomeComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
