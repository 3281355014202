import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {APIService} from "../../service/api.service";
import {AngularFireAuth} from "@angular/fire/auth";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import UserCredential = firebase.auth.UserCredential;
import {DeviceDetectorService} from "ngx-device-detector";
import {Profile} from "../../models/User";

@Component({
  selector: 'app-sign-in-dialog',
  templateUrl: './sign-in-dialog.component.html',
  styleUrls: ['./sign-in-dialog.component.scss']
})
export class SignInDialogComponent {
  isSignUp = false;

  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required, Validators.minLength(6)]);
  forgotPasswordMode = false;
  isLoading = false
  agreeTerms = false;

  constructor(public translate: TranslateService, public dialogRef: MatDialogRef<SignInDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data, public apiService: APIService, public auth: AngularFireAuth,
              private _snackBar: MatSnackBar) {
    if (data['isSignUp']) {
      this.isSignUp = true;
    }
  }

  signInToFirebase() {
    if (!this.email.valid || !this.password.valid) {
      return;
    }
    this.isLoading = true;
    this.auth.signInWithEmailAndPassword(this.email.value, this.password.value).then((result) => {
      // result.user.sendEmailVerification()
      this.signInToServer(result);
      // this.dialogRef.close();
    }).catch((error) => {
      if (error.code == 'auth/user-not-found') {
        this.translate.get('sign_in_fail').subscribe((res: string) => {
          this.openSnackBar(res);
        });
      } else if (error.code == 'auth/wrong-password') {
        this.translate.get('sign_in_fail').subscribe((res: string) => {
          this.openSnackBar(res);
        });
      } else {
        this.translate.get('unexpected_error').subscribe((res: string) => {
          this.openSnackBar(res);
        });
      }
      this.isLoading = false;
    }).finally(() => {
      // this.isLoading = false;
    });
  }

  signInToServer(user: UserCredential) {
    user.user.getIdToken().then(token => {
      this.apiService.signIn(token).subscribe((user) => {
        this.afterSignIn(user);
        // console.log(user);
        // if (user.id == null || user.id < 0) {
        //   this.translate.get('unexpected_error').subscribe((res: string) => {
        //     this.openSnackBar(res);
        //   });
        // } else {
        //   this.dialogRef.close(user);
        // }
      }, (e) => {
        this.translate.get('unexpected_error').subscribe((res: string) => {
          this.openSnackBar(res);
        });
      }, () => {
        this.isLoading = false;
      });
    })
  }

  resetPassword() {
    if (!this.email.valid) {
      return;
    }
    this.isLoading = true;
    this.auth.sendPasswordResetEmail(this.email.value).then((result) => {
      this.translate.get('reset_email_sent').subscribe((res: string) => {
        this.openSnackBar(res);
      });
      this.dialogRef.close();
    }).catch((error) => {
      if (error.code == 'auth/user-not-found') {
        this.translate.get('no_email_found').subscribe((res: string) => {
          this.openSnackBar(res);
        });
      } else {
        this.translate.get('unexpected_error').subscribe((res: string) => {
          this.openSnackBar(res);
        });
      }
    }).finally(() => {
      this.isLoading = false;
    });
  }

  signUpToFirebase() {
    if (!this.email.valid || !this.password.valid || !this.agreeTerms) {
      return;
    }
    this.isLoading = true;
    // this.auth;
    // this.auth.ver
    // this.auth.languageCode.then(r => {
    //   console.log(r);
    //   r = 's';
    // })
    this.auth.createUserWithEmailAndPassword(this.email.value, this.password.value).then((result) => {
      this.signUpToServer(result);
      // console.log();
    }).catch((error) => {
      console.log(error);
      if (error.code == 'auth/email-already-in-use') {
        this.translate.get('this_email_signed_up').subscribe((res: string) => {
          this.openSnackBar(res);
        });
      } else {
        this.translate.get('unexpected_error').subscribe((res: string) => {
          this.openSnackBar(res);
        });
      }
      this.isLoading = false;
    }).finally(() => {
      // this.isLoading = false;
    });
  }

  afterSignIn(user: Profile) {
    if (user.id == null || user.id < 0) {
      this.translate.get('unexpected_error').subscribe((res: string) => {
        this.openSnackBar(res);
      });
    } else {
      this.dialogRef.close(user);
    }
  }

  signUpToServer(user: UserCredential) {
    user.user.getIdToken().then(token => {
      this.apiService.signUp(token).subscribe((user) => {
        // console.log(user);
        this.afterSignIn(user);

      }, (e) => {
        this.translate.get('unexpected_error').subscribe((res: string) => {
          this.openSnackBar(res);
        });
      }, () => {
        this.isLoading = false;
      });
    })
  }

  cancelResetPassword() {
    this.forgotPasswordMode = false;
  }

  openSnackBar(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }

  forgetPasswordClick() {
    this.forgotPasswordMode = true;
  }
}
