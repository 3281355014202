<div>
  <img [src]="getUrlPhoto()" style="margin-right:4px;border-radius: 50%;display: inline-block;"
       [ngStyle]="{'width':iconSize+'px','height':iconSize+'px'}">
  <div style="margin-top: auto;margin-bottom: auto;display: inline-block;">
    <div>{{profile.name}}</div>
    <div *ngIf="profile.getLikeCount()>0">
      <mat-icon aria-hidden="false" style="font-size: 12px;width:12px;height: 12px">favorite</mat-icon>
      {{profile.getLikeCount()}}</div>
  </div>
</div>
