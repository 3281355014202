import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationService} from "../../service/AuthenticationService";
import {APIService} from "../../service/api.service";
import {Animal} from "../../models/Animal";
import {DonatedObject} from "../../models/DonatedObject";
import {NameValuePair} from "../../models/NameValuePair";
import {Profile} from "../../models/User";
import {Fossil} from "../../models/Fossil";
import {Item} from "../../models/Item";
import {KKCD} from "../../models/KKCD";
import {Villager} from "../../models/Villager";
import {ActivatedRoute, Router} from "@angular/router";
import {CritterpediaDetailComponent} from "../critterpedia-detail/critterpedia-detail.component";
import {MatDialog} from "@angular/material/dialog";
import {CritterpediaDialogComponent} from "../../dialog/critterpedia-dialog/critterpedia-dialog.component";

@Component({
  selector: 'app-critterpedia',
  templateUrl: './critterpedia.component.html',
  styleUrls: ['./critterpedia.component.scss']
})
export class CritterpediaComponent implements OnInit {
  @Input() uid: string;
  collected_key: string = '';
  @Input() isShowTitle: boolean = true;
  isLoading = true;
  type = 'fish';
  animals: Animal[] = [];
  filterList: NameValuePair[] = [];

  constructor(service: AuthenticationService, protected apiService: APIService, private router: Router,
              private route: ActivatedRoute, public dialog: MatDialog) {
    if (this.uid == null) {
      this.uid = service.getUid();
    }
  }

  handleParas() {
    this.route.queryParams.subscribe(params => {
      this.type = params['type'];
      if (this.type == null || this.type.length == 0) {
        this.type = 'fish';
      }
    });
  }

  getFilterList(): NameValuePair[] {
    // let fish = 0;
    // let insect = 0;
    // for (let d of this.collectedList) {
    //   if (d.collected_key == 'fish') {
    //     fish++;
    //   } else if (d.collected_key == 'insect') {
    //     insect++;
    //   }
    // }
    return [
      new NameValuePair('fish', 'fish'),
      new NameValuePair('insects', 'insect'),
      new NameValuePair('sea_creatures', 'dive_fish'),
    ];
  }

  ngOnInit(): void {

    this.handleParas();
    this.filterList = this.getFilterList();
    this.loadItems();
  }

  itemClicked(animal) {
    console.log(animal);
    const dialogRef = this.dialog.open(CritterpediaDialogComponent, {
      width: '500px',
      data: {aid: animal.id}
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed'+result);
      // reload
      this.loadItems();
      // this.animal = result;
    });
  }

  //
  filterChanged(type) {
    this.type = type;
    this.router.navigate(
      [],
      {
        queryParams: {
          type: type
        },
        queryParamsHandling: 'merge'
      }
    );
    this.loadItems();
  }

  protected loadItems() {
    if (!this.isShowTitle) {
      this.collected_key = this.type;
    } else {
      this.collected_key = '';
    }
    // this.isLoading = true;
    this.apiService.getAnimals(this.type, this.uid, this.collected_key).subscribe(animals => {
      this.animals = [];
      for (let a of animals) {
        let animal = new Animal(a);
        // animal.donatedObject = this.handleCustomCollected( animal.gameId.toString(), this.type);
        this.animals.push(animal);
      }
    }, null, () => {
      this.isLoading = false;
    });
  }

}
