import {Component, Inject, OnInit} from '@angular/core';
import {OverlayContainer} from "@angular/cdk/overlay";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-common-dialog',
  templateUrl: './common-dialog.component.html',
  styleUrls: ['./common-dialog.component.scss']
})
export class CommonDialogComponent implements OnInit {
  title = '';
  confirm_text = 'confirm';
  is_show_cancel = false;

  constructor(overlayContainer: OverlayContainer, public dialogRef: MatDialogRef<CommonDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.title = data['title'];
    this.confirm_text = data['confirm_text'] ?? this.confirm_text;
    this.is_show_cancel = data['is_show_cancel'] ?? this.is_show_cancel;
    if ((localStorage.getItem('dark-theme') ?? 'false') == 'true') {
      overlayContainer.getContainerElement().classList.add('unicorn-dark-theme');
    }
  }

  ngOnInit(): void {
    // this.dialogRef.
  }

  onClose(): void {
    this.dialogRef.close();
  }

  confirmClicked(): void {
    this.dialogRef.close(true);
  }
}

