import {Component, Input, OnInit} from '@angular/core';
import {DonatedObject} from "../../models/DonatedObject";
import {Fossil} from "../../models/Fossil";
import {NameValuePair} from "../../models/NameValuePair";
import {APIService} from "../../service/api.service";
import {Item} from "../../models/Item";
import {AuthenticationService} from "../../service/AuthenticationService";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-furniture',
  templateUrl: './furniture.component.html',
  styleUrls: ['./furniture.component.scss']
})
export class FurnitureComponent implements OnInit {

  @Input() category: string = 'furniture';
  @Input() collectedList: DonatedObject[] = [];
  @Input() uid: string;
  @Input() collected_key: string = '';
  isLoading = true;
  type: string;
  data: Fossil[] = [];
  filterList: NameValuePair[] = [];
  isShowTitle = false;
  pageIndex = 0;
  length = 0;
  pageSize = 20;

  constructor(service: AuthenticationService, protected apiService: APIService, private router: Router,
              private route: ActivatedRoute, public dialog: MatDialog) {
    let category = route.snapshot.data['type'];
    if (category != null) {
      this.category = category;
      this.isShowTitle = true;
    }
    // console.log(route.snapshot.data['title']);
    if (this.uid == null) {
      this.uid = service.getUid();
    }
  }

  getFilterList(): NameValuePair[] {
    if (this.category == 'furniture') {
      return [
        new NameValuePair('all', 'furniture'),
        new NameValuePair('tool', 'tool'),
        new NameValuePair('housewares', 'housewares'),
        new NameValuePair('miscellaneous', 'miscellaneous'),
        new NameValuePair('wall_mounted', 'wall-mounted'),
        new NameValuePair('wallpaper', 'wallpaper'),
        new NameValuePair('rugs', 'rugs'),
        new NameValuePair('floor', 'floor'),
        new NameValuePair('fencing', 'fencing'),
        new NameValuePair('other', 'other'),
        new NameValuePair('equipment', 'equipment'),
        new NameValuePair('Photo', 'Photo'),
        new NameValuePair('Poster', 'Poster'),
      ];
    } else {
      return [
        new NameValuePair('all', 'apparel'),
        new NameValuePair('Headwear', 'Headwear'),
        new NameValuePair('Dress_up', 'Dress-up'),
        new NameValuePair('top', 'top'),
        new NameValuePair('bottom', 'bottom'),
        new NameValuePair('sock', 'sock'),
        new NameValuePair('shoe', 'shoe'),
        new NameValuePair('Accessory', 'Accessory'),
        new NameValuePair('bag', 'bag'),
        new NameValuePair('Umbrella', 'Umbrella'),
      ];
    }

  }

  ngOnInit(): void {
    this.filterList = this.getFilterList();
    if (this.type == null) {
      this.type = this.category;
    }

    this.loadItems();
  }

  itemClicked(data) {
    console.log(data);
  }

  filterChanged(type) {
    this.type = type;
    this.loadItems();
  }

  handleCustomCollected(collectedList: DonatedObject[], item_id: string, key?: string): DonatedObject {
    for (let d of collectedList) {
      if (key != null) {
        if (d.collected_key == key && item_id == d.item_id) {
          return d;
        }
      } else {
        if (item_id == d.item_id) {
          return d;
        }
      }
    }
    return null;
  }

  pageChanged(newValue) {
    this.pageIndex = newValue;
    this.loadItems();
  }

  protected loadItems() {
    // this.isLoading = true;
    this.apiService.getFurniture(this.uid, this.type, this.pageIndex + 1, this.pageSize, this.collected_key).subscribe(serializerItem => {
      this.data = [];
      for (let a of serializerItem.items) {
        let animal = new Item(a);
        animal.donatedObject = this.handleCustomCollected(this.collectedList, animal.internal_id.toString(), Item.KEY_OWN_ITEM);
        this.data.push(animal);
      }
      this.length = serializerItem._meta.totalCount;
    }, null, () => {
      this.isLoading = false;
    });
  }
}
