import {DonatedObject} from "./DonatedObject";
import {BaseClass} from "./BaseClass";

export abstract class BaseSimpleModel extends BaseClass {
  id: number;
  name: string;
  image: string;
  sell_price: number;
  selling_count: number;
  looking_for_count: number;
  filename: string;
  unique_entry_id: string;
  internal_id: number;
  diy: boolean;
  variation: string;
  t_name: string;
  donatedObject: DonatedObject;

  getWebImage() {
    return this.image;
  }

  getName() {
    if (this.t_name == null || this.t_name.length == 0) {
      return this.name;
    }

    return this.t_name;
  }

  titleCaseWord(word: string) {
    if (!word || word.length <= 1) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }
}
