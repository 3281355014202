<mat-card style="width: 100%;cursor: pointer;overflow-y: auto;
    height: 100%; margin:8px;" class="row align-items-center ripple-container" matRipple>
  <!--  <div>-->
  <!--  <div class="item-tags">-->
  <!--    <div>-->
  <!--      <collected-icon matIcon="star" [amount]="diy.amount.toString()" *ngIf="diy!=null"></collected-icon>-->
  <!--      <collected-icon matIcon="local_library" [amount]="buildList.amount.toString()"-->
  <!--                      *ngIf="buildList!=null"></collected-icon>-->
  <!--    </div>-->
  <!--  <div>-->
  <!--  <div style="right: 0;top: 0;position: absolute;">-->
  <!--    s-->
  <!--  </div>-->
  <!--  </div>-->
  <!--  </div>-->
  <!--  <img [src]="image" class="item-image" [style]="'filter: grayscale('+gray+'%);'">-->
  <!--  &lt;!&ndash;  </div>&ndash;&gt;-->
  <!--  <div class="item-name">{{name}}</div>-->
  <div class="row">
    <div class="col-9">
      <div style="margin-bottom: 8px;">
        <app-common-tag *ngIf="room.isHost(uid)" [text]="'host'"
                        [color]="'#2196F3'"></app-common-tag>
        <app-common-tag *ngIf="room.my_position!=null" [text]="room.my_position.current_status"
                        [color]="room.my_position.getMyStatusColor()"></app-common-tag>
        <span style="font-size: 22px;font-weight: bold;">{{room.name}}</span>
      </div>
      <app-user-profile-row [profile]="room.host" [iconSize]="36"></app-user-profile-row>
    </div>
    <div class="col-3">
      <app-common-tag [text]="room.code"></app-common-tag>
      <br>
      <app-common-tag [text]="room.host.getLanguage()"></app-common-tag>
    </div>
  </div>
  <app-room-tags [room]="room" style="display: block;margin-top: 8px;"></app-room-tags>
  <app-room-tip *ngIf="room.type!='free'" [room]="room"></app-room-tip>

  <div style="margin-top: 8px">{{room.notes}}</div>
  <div style="margin-top: 8px">
    <mat-icon aria-hidden="false" style="font-size: 12px;width:12px;height: 12px">people</mat-icon>
    <span>{{room.people_waiting}}/{{room.max_participant}}</span>
  </div>
  <div style="text-align: right;">{{room.getCreatedTime()}}</div>
  <button mat-raised-button (click)="leave()"
          *ngIf="!room.isHost(uid) && room.isJoined()">{{'leave'|translate}}</button>
  <button mat-raised-button (click)="openEditDialog()"
          *ngIf="room.isHost(uid) ">{{'edit_room'|translate}}</button>
  <button mat-raised-button (click)="closeRoomClicked()"
          *ngIf="room.isHost(uid) ">{{'close'|translate}}</button>
  <!--  <div>{{room.name}}</div>-->
</mat-card>
