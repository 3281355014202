export const WISHLIST_TYPE = {
  NORMAL: 'normal',
  FAVOURITE: 'favourite',
  OWNED: 'owned',
};

export const WISHLIST_ORDER = {
  ID_DEC: '',
  QUANTITY_DEC: '-amount',
};
