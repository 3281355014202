import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {DonatedObject} from "../../models/DonatedObject";
import {UpperCasePipe} from "@angular/common";

@Component({
  selector: 'app-item-card-container',
  templateUrl: './item-card-container.component.html',
  styleUrls: ['./item-card-container.component.scss']
})
export class ItemCardContainerComponent implements OnInit {
  @Input() image: string = '';
  @Input() name: string = '';
  @Input() diy: DonatedObject;
  @Input() buildList: DonatedObject;
  @Input() owned: DonatedObject;
  @Input() donated: DonatedObject;
  @Input() favorite: DonatedObject;
  @Input() wishlist: DonatedObject;
  // @Output() click = new EventEmitter<void>();
  @Input() gray: number = 0;

  constructor() {

  }

  // clicked() {
  //   console.log('clicked');
  //   this.click.emit();
  // }

  ngOnInit(): void {
    // this.gray = this.donated == null ? 100 : 0;
  }

}
