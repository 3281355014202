import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../service/AuthenticationService';
import { APIService } from '../../service/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { WishList } from '../../models/WishList';
import { WishlistEditDialogComponent } from './wishlist-edit-dialog/wishlist-edit-dialog.component';
import { CommonDialogComponent } from 'src/app/dialog/common-dialog/common-dialog.component';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss'],
})
export class WishlistComponent implements OnInit {
  data: WishList[] = [];
  currentEditIndex = -1;
  isLoading = true;

  constructor(
    private service: AuthenticationService,
    protected apiService: APIService,
    private route: ActivatedRoute,
    public _snackBar: MatSnackBar,
    private router: Router,
    public translate: TranslateService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (!this.service.isSignedIn()) {
      //TODO: return to home page
      return;
    }
    this.loadWishlists();
  }

  loadWishlists() {
    this.data = [];
    // first two items are hard coded
    let defaultFavourite = new WishList();
    defaultFavourite.id = -1;
    defaultFavourite.title = 'wishlist';
    defaultFavourite.des = 'default_';
    let defaultOwned = new WishList();
    defaultOwned.id = -2;
    defaultOwned.title = 'owned';
    defaultOwned.des = 'default_';

    this.data.push(defaultFavourite);
    this.data.push(defaultOwned);
    this.apiService.getWishlists().subscribe((wishlists) => {
      this.data = [...this.data, ...wishlists];
      this.isLoading = false;
    });
  }

  createWishlist(title: string) {
    let wishlist = new WishList();
    wishlist.title = title;
    this.isLoading = true;
    this.apiService
      .createWishlist(wishlist)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((res) => {
        this.data.push(res);
      });
  }

  editWishlist(title: string) {
    let wishList = { ...this.data[this.currentEditIndex] } as WishList;
    wishList.title = title;
    this.isLoading = true;
    this.apiService
      .updateWishlist(wishList)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.data[this.currentEditIndex] = res;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  onDeleteItem(index) {
    const dialogRef = this.dialog.open(CommonDialogComponent, {
      width: '500px',
      data: {
        title: 'confirm_to_delete',
        is_show_cancel: true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != null) {
        this.isLoading = true;
        this.apiService
          .deleteWishlist(this.data[index])
          .pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
          .subscribe(
            (res) => {
              this.data.splice(index, 1);
            },
            (error) => {
              console.log(error);
            }
          );
      }
    });
  }

  showEditDialog(index) {
    this.currentEditIndex = index;
    const dialogRef = this.dialog.open(WishlistEditDialogComponent, {
      width: '500px',
      data: {
        wishlistName: index >= 0 ? this.data[index].title : '',
      },
    });

    dialogRef.afterClosed().subscribe(({ isOk, title }) => {
      if (isOk) {
        if (this.currentEditIndex < 0) {
          this.createWishlist(title);
        } else {
          this.editWishlist(title);
        }
      }
    });
  }
}
