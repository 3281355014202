import {Component, Inject, OnInit} from '@angular/core';
import {OverlayContainer} from "@angular/cdk/overlay";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Room, RoomParticipant} from "../../../models/Room";
import {AuthenticationService} from "../../../service/AuthenticationService";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {APIService} from "../../../service/api.service";

@Component({
  selector: 'app-room-dialog',
  templateUrl: './room-dialog.component.html',
  styleUrls: ['./room-dialog.component.scss']
})
export class RoomDialogComponent implements OnInit {
  room: Room;

  constructor(public service: AuthenticationService, overlayContainer: OverlayContainer,
              public dialogRef: MatDialogRef<RoomDialogComponent>, public _snackBar: MatSnackBar,
              public translate: TranslateService, public apiService: APIService,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.room = data;
    if ((localStorage.getItem('dark-theme') ?? 'false') == 'true') {
      overlayContainer.getContainerElement().classList.add('unicorn-dark-theme');
    }
  }

  ngOnInit(): void {
  }

  onClose(): void {
    // set the closeMessage property here
    // this.closeMessage = "Pizza!";
    // this.dialogRef.
    this.dialogRef.close('ref');
  }

  joinClicked() {
    if (!this.service.isSignedIn()) {
      this.translate.get('please_sign_in_first').subscribe(string => {
        this._snackBar.open(string, null, {
          duration: 2000,
        });
      })
      return;
    }

    this.apiService.joinRoom(this.room.id).subscribe((result) => {
      let roomParticipant: RoomParticipant;
      try {
        // @ts-ignore
        if (result == 'blocked') {
          this.translate.get('you_have_been_blocked').subscribe(string => {
            this._snackBar.open(string, null, {
              duration: 2000,
            });
          })
          return;
        }
        roomParticipant = new RoomParticipant(result);
        this.dialogRef.close(roomParticipant);
      } catch (e) {
        console.log(e);
        this.translate.get('unexpected_error').subscribe(string => {
          this._snackBar.open(string, null, {
            duration: 2000,
          });
        })
      }
    }, (e) => {
      console.log(e);
    });
  }
}
