import {Component, Input, OnInit} from '@angular/core';
import {DonatedObject} from "../../models/DonatedObject";
import {KKCD} from "../../models/KKCD";
import {NameValuePair} from "../../models/NameValuePair";
import {APIService} from "../../service/api.service";
import {Villager} from "../../models/Villager";
import {AuthenticationService} from "../../service/AuthenticationService";

@Component({
  selector: 'app-villager',
  templateUrl: './villager.component.html',
  styleUrls: ['./villager.component.scss']
})
export class VillagerComponent implements OnInit {

  @Input() collectedList: DonatedObject[] = [];
  @Input() uid: string;
  @Input() isShowTitle: boolean = true;

  isLoading = true;
  type = 'islander_dondate';
  data: Villager[] = [];
  filterList: NameValuePair[] = [];
  pageIndex = 0;
  length = 0;
  pageSize = 20;

  constructor(service: AuthenticationService, protected apiService: APIService) {
    if (this.uid == null) {
      this.uid = service.getUid();
    }
  }

  getFilterList(): NameValuePair[] {
    let filters: NameValuePair[] = [];
    if (this.isShowTitle) {
      filters.push(new NameValuePair('all', ''));
    }
    filters.push(new NameValuePair('your_villagers', Villager.KEY_DONATE));
    filters.push(new NameValuePair('favourite', Villager.KEY_FAVOURITE));
    return filters;
  }

  ngOnInit(): void {
    if (this.isShowTitle) {
      this.type = '';
    }
    this.filterList = this.getFilterList();
    this.loadItems();
  }

  itemClicked(data) {
    console.log(data);
  }

  filterChanged(type) {
    this.type = type;
    this.loadItems();
  }

  handleCustomCollected(collectedList: DonatedObject[], item_id: string, key?: string): DonatedObject {
    for (let d of collectedList) {
      if (key != null) {
        if (d.collected_key == key && item_id == d.item_id) {
          return d;
        }
      } else {
        if (item_id == d.item_id) {
          return d;
        }
      }
    }
    return null;
  }


  pageChanged(newValue) {
    this.pageIndex = newValue;
    this.loadItems();
  }

  protected loadItems() {
    // this.isLoading = true;
    this.apiService.getVillagers(this.uid, this.pageIndex + 1, this.pageSize, this.type).subscribe(result => {
      this.data = [];
      for (let a of result.items) {
        let animal = new Villager(a);
        animal.donatedObject = this.handleCustomCollected(this.collectedList, animal.id.toString(), Villager.KEY_DONATE);
        animal.favourite = this.handleCustomCollected(this.collectedList, animal.id.toString(), Villager.KEY_FAVOURITE);
        this.data.push(animal);
      }
      this.length = result._meta.totalCount;
    }, null, () => {
      this.isLoading = false;
    });
  }

}
