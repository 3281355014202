import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "../../service/AuthenticationService";
import {APIService} from "../../service/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {Animal} from "../../models/Animal";
import {CatalogScanner} from "../../models/CatalogScanner";
import {Item} from "../../models/Item";

@Component({
  selector: 'app-catalog-scanner',
  templateUrl: './catalog-scanner.component.html',
  styleUrls: ['./catalog-scanner.component.scss']
})
export class CatalogScannerComponent implements OnInit {
  csCode: string;
  langCode = 'en';
  data: CommonData[];
  isLoading = true;
  type:string='';

  constructor(private service: AuthenticationService, protected apiService: APIService, private route: ActivatedRoute, private _snackBar: MatSnackBar,
              private router: Router, public translate: TranslateService) {
    this.langCode = localStorage.getItem('langCode') ?? 'en';
    if(this.langCode=='zh'){
      this.langCode = 'zh_TW';
    }else if(this.langCode=='zh-CN'){
      this.langCode = 'zh_CN';
    }
    this.handleParams();
    // console.log('constructor');
    // console.log(this.langCode);
  }

  handleParams() {
    this.route.params.subscribe(params => {
      this.csCode = params['code'];
      console.log(this.csCode);
      this.loadItems();
    });

  }

  ngOnInit(): void {
    // this.loadItems();
    // console.log('ngOnInit');
  }

  protected loadItems() {
    console.log(this.csCode);
    this.apiService.loadCatalogScanner(this.csCode, this.langCode).subscribe(c => {
      console.log(c);
      this.isLoading = false;
      this.type = c.type;
      switch (c.type) {
        case 'catalog':
        case 'recipes':
          this.data = this.buildCatalogData(c.data);
          break;
          case 'critters':
          this.data = this.buildCrittersData(c.data);
          break;

        //TODO: other type
      }
      // this.data = c.data;
      // console.log(a);
      // this.animal = new Animal(a);
      // this.months = this.animal.getMonthList(this.langCode);
      // this.times = this.animal.getTimeList();
    }, null, () => {
      // this.isLoading = false;
    });
  }

  buildCatalogData(data: Item[]) {
    let tmp = [];
    for (let d of data) {
      // console.log(d.name);
      tmp.push(new CommonData(d['image'], d.t_name ?? d.name));
    }
    return tmp;
  }

  buildCrittersData(data: Animal[]) {
    let tmp = [];
    for (let d of data) {
      // console.log(d.name);
      tmp.push(new CommonData(d['icon_image'], d.t_name ?? d.name));
    }
    return tmp;
  }

}

class CommonData {
  image: string;
  title: string;

  constructor(image: string, title: string) {
    this.image = image;
    this.title = title;
  }
}
