import {Component, Input, OnInit} from '@angular/core';
import {Room} from "../../models/Room";

@Component({
  selector: 'app-room-tip',
  templateUrl: './room-tip.component.html',
  styleUrls: ['./room-tip.component.scss']
})
export class RoomTipComponent implements OnInit {
  @Input() room: Room;

  constructor() {
  }

  ngOnInit(): void {
  }

}
