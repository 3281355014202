import { DonatedObject } from './DonatedObject';
import { BaseSimpleModel } from './BaseSimpleModel';
import { Item } from './Item';

export class WishList extends BaseSimpleModel {
  id: number;
  uid: number;
  title: string;
  des: string;
  code: string;
  
  constructor(data: WishList = null) {
    super(data);
  }

  //TODO: this is Dart code, please convert to ts
  // int id;
  // int uid;
  // int ordering;
  // String icon;
  // String tag;
  // String color;
  // String title;
  // String code;
  // String id_key;
  // String des;
  // bool isDeleted;
  // String create_at;
  // String last_edit;
  // String last_update;
  // List<DonatedObject> items;

  toJson() {
    //TODO: all parameters like ordering, icon... expect "List<DonatedObject> items"
    return {
      id: this.id,
      uid: this.uid,
    };
  }
}
