import {Component, Inject, OnInit} from '@angular/core';
import {OverlayContainer} from "@angular/cdk/overlay";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Room, RoomTag} from "../../models/Room";
import {FormControl, Validators} from "@angular/forms";
import {FilterPair} from "../../models/FilterPair";
import {NameValuePair} from "../../models/NameValuePair";
import {AuthenticationService} from "../../service/AuthenticationService";
import {APIService} from "../../service/api.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-manage-room-info',
  templateUrl: './manage-room-info.component.html',
  styleUrls: ['./manage-room-info.component.scss']
})
export class ManageRoomInfoComponent implements OnInit {
  room: Room;
  confirm_text = 'confirm';
  is_show_cancel = false;
  roomName: FormControl;
  islandName: FormControl;
  characterName: FormControl;
  connectCode: FormControl;
  TYPE_FREE = Room.TYPE_FREE;
  TYPE_TIPS_REQUIRED = Room.TYPE_TIPS_REQUIRED;
  tagSelectedColor = '#123123';
  tagUnselectedColor = '#00000000';
  roomTagFilter = new FilterPair('room_tags', [
    new NameValuePair('turnip', 'turnip'),
    new NameValuePair('meteor_shower', 'meteor_shower'),
    new NameValuePair('touch', 'touch'),
    new NameValuePair('special_character', 'special_character'),
    new NameValuePair('free_gift', 'free_gift'),
    new NameValuePair('event', 'event'),
  ]);
  howToLeaveFilter = new FilterPair('how_to_leave', [
    new NameValuePair('leave_from_airport', 'airport'),
    new NameValuePair('use_minus_button', 'minus'),
    new NameValuePair('other', 'other'),
  ]);
  selectedTag: boolean[] = [];

  constructor(overlayContainer: OverlayContainer, public dialogRef: MatDialogRef<ManageRoomInfoComponent>,
              @Inject(MAT_DIALOG_DATA) public data, protected service: AuthenticationService, protected apiService: APIService,
              protected _snackBar: MatSnackBar) {
    this.room = data['room'];
    if (this.room == null) {
      this.room = new Room();
      this.room.island_name = this.service.getIslandName();
      this.room.c_name = this.service.getUserName();
    }
    this.roomName = new FormControl(this.room.name, [Validators.required]);
    this.islandName = new FormControl(this.room.island_name, [Validators.required]);
    this.characterName = new FormControl(this.room.c_name, [Validators.required]);
    this.connectCode = new FormControl(this.room.island_passcode, [Validators.required, Validators.minLength(5), Validators.maxLength(5)]);


    // console.log('Room:room.ticket');
    // console.log(this.room.ticket);
    for (let i = 0; i < this.roomTagFilter.pairs.length; i++) {
      this.selectedTag.push(false);
    }


    for (let j = 0; j < this.room.tags.length; j++) {
      for (let i = 0; i < this.roomTagFilter.pairs.length; i++) {
        if (this.room.tags[j].tag == this.roomTagFilter.pairs[i].value) {
          this.selectedTag[i] = true;
        }
      }
    }

    console.log('this.selectedTag');
    console.log(this.selectedTag);

    if ((localStorage.getItem('dark-theme') ?? 'false') == 'true') {
      // dark
      this.tagSelectedColor = '#b3bfff';
      overlayContainer.getContainerElement().classList.add('unicorn-dark-theme');
    } else {
      // light
      this.tagSelectedColor = '#548dff';
    }
  }

  tagClicked(index) {
    this.selectedTag[index] = !this.selectedTag[index];
    if (this.selectedTag[index]) {
      this.room.tags.push(new RoomTag(null, this.roomTagFilter.pairs[index].value));
    } else {
      for (let i = 0; i < this.room.tags.length; i++) {
        if (this.room.tags[i].tag == this.roomTagFilter.pairs[index].value) {
          this.room.tags.splice(i, 1);
          return;
        }
      }
    }
  }

  test(event) {
    console.log(event);
  }

  ngOnInit(): void {
    // this.dialogRef.
  }

  onClose(): void {
    this.dialogRef.close();
  }

  isValid() {
    return this.roomName.valid && this.islandName.valid && this.characterName.valid && this.connectCode.valid;
  }

  async confirmClicked() {
    if (!this.isValid()) {
      return;
    }
    let result: Room;
    if (this.room.id == null) {
      // create
      result = await this.apiService.createRoom(this.room).toPromise();
    } else {
      // update
      result = await this.apiService.updateRoom(this.room).toPromise();
    }
    if (result == null || typeof result == 'string') {
      this._snackBar.open('unexpected_error', null, {duration: 3000});
      return;
    }

    this.dialogRef.close(result);
  }
}
